import {forkJoin} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {UploadService} from '../../services/upload.service';
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {NgxFileDropEntry, FileSystemFileEntry} from 'ngx-file-drop';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;
  @Input() accept: string;
  @Input() multiple = false;
  form = this.fb.group({
    type: [''],
    file: [null],
  });
  loading = false;

  public files: { file: File, fileDropEntry: NgxFileDropEntry, preview?: string | ArrayBuffer }[] = [];

  constructor(protected dialogRef: NbDialogRef<UploadFileComponent>,
              public fb: FormBuilder,
              private toastrService: NbToastrService,
              private uploadService: UploadService,
              private apiService: ApiService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.type) {
      this.form.get('type').setValue(this.type);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit(data) {
    if (!this.files?.length) {
      return;
    }
    console.log(this.files);

    this.loading = true;

    if (this.multiple) {
      const requests = [];
      this.files.forEach(fileItem => {
        const formData = this.apiService.createFormData({
          type: this.form.get('type').value,
          file: fileItem.file
        });
        requests.push(this.uploadService.uploadFile(formData));
      });

      forkJoin(...requests).subscribe(files => {
        this.loading = false;
        if (files?.length) {
          const param = files.map(el => el.data);
          this.dialogRef.close(param);
        }
      }, err => {
        this.loading = false;
        this.toastrService.danger(null, 'File not loaded!');
      });

    } else {
      const sendData = Object.assign(data, {
        file: this.files[0].file
      });
      const formData = this.apiService.createFormData(sendData);
      this.uploadService.uploadFile(formData)
        .subscribe(res => {
          const param = {
            id: res.data.id,
            name: res.data.name,
          };
          this.dialogRef.close(param);
        }, error => {
          this.loading = false;
          this.toastrService.danger(null, error?.error?.message);
        })
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const mimeType = file.type;
          if (mimeType.match(/image\/*/)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.files.push({file, fileDropEntry: droppedFile, preview: reader.result});
            };
          } else {
            this.files.push({file, fileDropEntry: droppedFile});
          }
        });
      }
    }
  }

  getImage(url) {
    if (url) {
      return this.sanitizer.bypassSecurityTrustUrl(url as string);
    }
  }

  deleteFile(index) {
    this.files.splice(index, 1);
  }

}
