import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbSelectModule,
  NbTooltipModule
} from '@nebular/theme';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared.module';
import { GlassesTableComponent } from './glasses-table.component';


@NgModule({
  declarations: [GlassesTableComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    NgxDatatableModule,
    NbTooltipModule,
    FormsModule,
    NbCheckboxModule,
    RouterModule,
    SharedModule
  ],
  exports: [GlassesTableComponent]
})
export class GlassesTableModule { }
