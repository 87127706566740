import { Pipe, PipeTransform } from '@angular/core';
import {formatDate} from '@angular/common';
@Pipe({name: 'dateSafe'})
export class DateSafePipe implements PipeTransform {
  transform(value: string | number | Date, format: string, locale: string = 'en-US', timezone?: string): string {
    if (!(value && format && locale)) {
      return '';
    }
    let newValue = value;
    if (typeof newValue === 'string') {
      newValue = newValue.replace(' ', 'T');
    }
    if (timezone) {
      return formatDate(newValue, format, locale, timezone);
    }
    return formatDate(newValue, format, locale);
  }
}
