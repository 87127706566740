import { Component, Input } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { DomainsService } from '../../services/domains.service';

@Component({
  selector: 'app-segment-confirm',
  templateUrl: './segment-confirm.component.html',
  styleUrls: ['./segment-confirm.component.scss']
})
export class SegmentConfirmComponent {
  @Input() message: string;
  @Input() segmentId: string;

  constructor(
    protected dialogRef: NbDialogRef<SegmentConfirmComponent>,
    private domainsService: DomainsService,
    private toastrService: NbToastrService,
  ) { }

  cancel() {
    this.dialogRef.close(false);
  }
  confirm() {
    this.domainsService.deleteSegment(this.segmentId).subscribe(({message}) => {
        this.toastrService.success(null, message);

        this.dialogRef.close(true);
    });
  }

}
