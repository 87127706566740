import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {User} from '../../interfaces/user.interface';

@Component({
  selector: 'app-select-customers',
  templateUrl: './select-customers.component.html',
  styleUrls: ['./select-customers.component.scss']
})
export class SelectCustomersComponent implements OnInit {
  @Input() customers: User[] = [];
  @Input() rows = [];
  @Input() selectedCustomer = 0;
  @Input() selectedGlassesIds = [];
  @Input() presentInCustomers = [];
  @Input() action = 'Add';
  customerObj = {};

  constructor(protected dialogRef: NbDialogRef<SelectCustomersComponent>) { }

  ngOnInit(): void {
    this.customerObj = this.customers.find(el => el.id === +this.selectedCustomer)
  }

  cancel() {
    this.dialogRef.close([]);
  }

  confirm() {
    this.dialogRef.close(this.customerObj);
  }
}
