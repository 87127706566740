import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
    NbAuthJWTInterceptor,
    NbAuthJWTToken,
    NbAuthModule,
    NbPasswordAuthStrategy,
    NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthSimpleToken, NbAuthSimpleInterceptor
} from '@nebular/auth';
import { AdminModule } from './admin/admin.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbButtonModule, NbSidebarModule, NbToastrModule, NbGlobalPhysicalPosition } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { CustomerModule } from './customer/customer.module';
import { CustomerAuthGuard } from './shared/guards/customer-auth.guard';
import { RefreshTokenInterceptor } from './shared/interceptors/refresh-token.interceptor';
import { RootAuthGuard } from './shared/guards/root-auth.guard';
import { SharedModule } from './shared/shared.module';
import { ApiService } from './shared/services/api.service';
import { UsersService } from './shared/services/users.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'default'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbButtonModule,
    SharedModule.forRoot(),
    NbToastrModule.forRoot({
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
    }),
    AdminModule,
    CustomerModule,
    NbSidebarModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthSimpleToken,
            key: 'token',
          },
          logout: {
            method: 'post',
          },
          requestPass: {
            endpoint: 'reset-password',
            method: 'post',
          },
        }),
      ],
      forms: {
        validation: {
          password: {
            required: true,
            minLength: 8,
            maxLength: 20,
            regexp: '^(?=.{8,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W).*$',
          },
        }
      },
    }),
  ],
  providers: [
    ApiService,
    UsersService,
    RootAuthGuard,
    CustomerAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NbAuthJWTInterceptor,
      multi: true
    },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: (req: HttpRequest<any>) => req.url === '/api/auth/refresh-token',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
