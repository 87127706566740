import { Component, OnInit } from '@angular/core';
import {
  NbIconLibraries,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
} from '@nebular/theme';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../shared/services/users.service';
import { DomainsService } from '../../../shared/services/domains.service';

import {PortalData} from '../../../shared/interfaces/portal-data.interface';

declare const portalData: PortalData;

@Component({
  selector: 'app-customer-layout',
  templateUrl: './customer-layout.component.html',
  styleUrls: ['./customer-layout.component.scss'],
})
export class CustomerLayoutComponent implements OnInit {
  appName = 'TryMe';
  portalData: PortalData = portalData;

  superDomainMode = false;

  selectSegment = null;

  segments = null;

  year = new Date().getFullYear();
  profile;

  topMenuItems: NbMenuItem[] = [
    {
        title: 'Glasses',
        icon: 'home-outline',
        link: 'glasses',
      },
      {
        title: 'Domain Settings',
        icon: 'settings-2-outline',
        link: 'settings/domain',
      },
  ];
  menuItems: NbMenuItem[] = [];

  profileMenuItems: NbMenuItem[] = [
    {
      title: 'Profile',
      icon: 'person',
      data: {
        key: 'edit',
      },
    },
    {
      title: 'Logout',
      icon: 'log-out-outline',
      data: {
        key: 'logout',
      },
    },
  ];

  constructor(
    private nbMenuService: NbMenuService,
    private route: ActivatedRoute,
    private router: Router,
    private sidebarService: NbSidebarService,
    public usersService: UsersService,
    private domainsService: DomainsService,
    iconsLibrary: NbIconLibraries
  ) {
    iconsLibrary.registerSvgPack('myicon', {
      glasses: `<svg width='20' height='20' viewBox='0 0 20 8' fill='#8F9CB3' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' clip-rule='evenodd' d='M19 4.75H17.5V3.25H19C19.4142 3.25 19.75 3.58579 19.75 4C19.75 4.41421 19.4142 4.75 19 4.75Z' />
                <path fill-rule='evenodd' clip-rule='evenodd' d='M1 3.25L2.5 3.25L2.5 4.75L1 4.75C0.585786 4.75 0.25 4.41421 0.25 4C0.25 3.58579 0.585787 3.25 1 3.25Z' />
                <path fill-rule='evenodd' clip-rule='evenodd' d='M8.52387 4.53673C8.52399 4.53662 8.52411 4.5365 8.00002 4C7.47594 3.4635 7.47607 3.46337 7.4762 3.46324L7.47713 3.46234L7.47871 3.4608L7.48301 3.45665L7.49613 3.44414C7.50683 3.434 7.52147 3.42031 7.53976 3.40358C7.57629 3.37017 7.62771 3.32436 7.69187 3.27029C7.8194 3.1628 8.00132 3.0192 8.22015 2.87446C8.63088 2.60278 9.28495 2.25 10 2.25C10.7151 2.25 11.3692 2.60278 11.7799 2.87446C11.9987 3.0192 12.1807 3.1628 12.3082 3.27029C12.3723 3.32436 12.4238 3.37017 12.4603 3.40358C12.4786 3.42031 12.4932 3.434 12.5039 3.44414L12.517 3.45665L12.5213 3.4608L12.5229 3.46234L12.5236 3.46296C12.5237 3.46309 12.5241 3.4635 12 4C11.4759 4.5365 11.4761 4.53662 11.4762 4.53673L11.4768 4.5373L11.4727 4.5334C11.4679 4.52889 11.4596 4.52109 11.448 4.51048C11.4247 4.4892 11.3885 4.45689 11.3415 4.41721C11.2465 4.3372 11.1115 4.2308 10.9524 4.12554C10.6072 3.89722 10.2613 3.75 10 3.75C9.73879 3.75 9.39286 3.89722 9.04767 4.12554C8.88853 4.2308 8.75352 4.3372 8.65859 4.41721C8.61153 4.45689 8.57534 4.4892 8.55208 4.51048C8.54047 4.52109 8.53216 4.52889 8.52739 4.5334L8.52297 4.53762L8.52387 4.53673ZM8.52297 4.53762C8.52269 4.53788 8.52278 4.5378 8.52297 4.53762V4.53762ZM11.4772 4.53775L11.4768 4.5373L11.4772 4.53775Z' />
                <path fill-rule='evenodd' clip-rule='evenodd' d='M7.5 4C7.5 5.10457 6.60457 6 5.5 6C4.39543 6 3.5 5.10457 3.5 4C3.5 2.89543 4.39543 2 5.5 2C6.60457 2 7.5 2.89543 7.5 4ZM9 4C9 5.933 7.433 7.5 5.5 7.5C3.567 7.5 2 5.933 2 4C2 2.067 3.567 0.5 5.5 0.5C7.433 0.5 9 2.067 9 4ZM16.5 4C16.5 5.10457 15.6046 6 14.5 6C13.3954 6 12.5 5.10457 12.5 4C12.5 2.89543 13.3954 2 14.5 2C15.6046 2 16.5 2.89543 16.5 4ZM18 4C18 5.933 16.433 7.5 14.5 7.5C12.567 7.5 11 5.933 11 4C11 2.067 12.567 0.5 14.5 0.5C16.433 0.5 18 2.067 18 4Z' />
                </svg>`,
    });
  }
  onToggle() {
    this.sidebarService.toggle(true);
    return false;
  }

  ngOnInit(): void {
    this.domainsService.superDomainMode.subscribe((superDomainMode) => {
        this.superDomainMode = superDomainMode;

        this.prepareMenu();
    });

    this.domainsService.segments.subscribe((segments) => {
      this.segments = segments;
    });
    this.domainsService.selectedSegmentId.subscribe((segmentId) => {
      this.selectSegment = segmentId;
    });

    this.usersService.getCurrentUserObservable().subscribe((res) => {
      this.profile = res;

      this.prepareMenu();
    });

    this.nbMenuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === 'profile-context-menu'))
      .subscribe(({ item }) => {
        if (item.data?.key === 'logout') {
          this.router.navigate(['/auth/logout']);
        } else if (item.data?.key === 'edit') {
          this.router.navigate(['/customer/users/' + this.profile.id]);
        }
      });
  }

  prepareMenu() {
    if (!this.profile) {
      return;
    }

    this.menuItems = [];
    if (this.superDomainMode) {
      this.menuItems = [
        {
          title: 'Segment Statistic',
          icon: 'bar-chart-outline',
          link: 'statistics/segment',
        },
        {
          title: 'Segment Settings',
          icon: 'settings-2-outline',
          link: 'settings/segment',
        },
        {
          title: 'How To',
          icon: 'question-mark-circle-outline',
          link: 'how-to/segment',
        },
      ];
      return;
    }

    if (this.profile.access_to_products.virtual_try_on.value) {
      this.menuItems = [
        {
            title: 'Glasses',
            icon: 'home-outline',
            link: 'glasses',
        },
        {
            title: 'Statistic',
            icon: 'bar-chart-outline',
            link: 'statistics/domain',
        },
        {
            title: 'Settings',
            icon: 'settings-2-outline',
            link: 'settings/domain',
        },
        {
            title: 'How To',
            icon: 'question-mark-circle-outline',
            link: 'how-to/domain',
        },
      ];
    }
  }

  setSegment(e) {
    this.domainsService.selectSegmentId(e);
  }
}
