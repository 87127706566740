import { GlassesResolverService } from './resolver/glasses-resolver.service';
import { UserResolverService } from './resolver/user-resolver.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'users/:id',
        resolve: {
          user: UserResolverService
        },
        data: {profile: true},
        loadChildren: () => import('../shared/components/user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule),
      },
      {
        path: 'team',
        loadChildren: () => import('./pages/team/team.module').then(m => m.TeamModule),
      },
      {
        path: 'customers/view/:id',
        resolve: {
          user: UserResolverService
        },
        loadChildren: () => import('../shared/components/user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
      {
        path: 'team/view/:id',
        resolve: {
          user: UserResolverService
        },
        loadChildren: () => import('../shared/components/user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
      {
        path: 'glasses',
        loadChildren: () => import('./pages/admin-glasses/admin-glasses.module').then(m => m.AdminGlassesModule),
      },
      {
        path: 'virtual-try-on',
        loadChildren: () => import('./pages/virtual-try-on/virtual-try-on.module').then(m => m.VirtualTryOnModule),
      },
      {
        path: 'brands',
        loadChildren: () => import('./pages/admin-brands/admin-brands.module').then(m => m.AdminBrandsModule),
      },
      {
        path: 'search-results/:query',
        loadChildren: () => import('./pages/search-result/search-result.module').then(m => m.SearchResultModule),
      },
      {
        path: 'glasses/view/:id',
        resolve: {
          glasses: GlassesResolverService
        },
        loadChildren: () => import('../shared/components/glasses-info/glasses-info.module').then(m => m.GlassesInfoModule),
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
