import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor( private api: ApiService) { }


  getCountries() {
    return this.api.get('api/get-countries');
  }
  getStates(countryCode) {
    let params = new HttpParams();
    params = params.append('cca3', countryCode);
    return this.api.get('api/get-states', params);
  }
  getCities(countryCode, state) {
    let params = new HttpParams();
    params = params.append('cca3', countryCode);
    params = params.append('state', state);
    return this.api.get('api/get-cities', params);
  }
}
