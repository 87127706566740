import { Pipe, PipeTransform } from '@angular/core';
import {formatDate} from '@angular/common';
@Pipe({name: 'domain'})
export class DomainPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    try {
      const url = new URL(value);
      return url.hostname.replace('www.', '');
    } catch (error) {
      return value.substring(0, 30) + '...';
    }
  }
}
