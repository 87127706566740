import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserRole } from '../enums/user-role.enum';
import { ApiService } from './api.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class GlassesService {

  constructor( private api: ApiService,
               private usersService: UsersService
  ) { }

  getColumnsOfGlasses() {
    const columns = [
      {name: '', prop: 'photos', visible: true},
      {name: 'ID', prop: 'id', visible: true},
      {name: 'Product Name', prop: 'product_name', visible: true},
      {name: 'Model Name', prop: 'name_model', visible: true},
      {name: 'Brand', prop: 'brand', visible: true},
      {name: 'EAN', prop: 'ean', visible: true},
      {name: 'Sun Glass', prop: 'sunglass', visible: true},
      {name: 'Normal Glass', prop: 'normal_glass', visible: true},
    ];
    return columns;
  }

  getGlasses(params, isShowExclusive = false) {
    const data = this.api.getParams(params);
    if ('domainId' in params && params.domainId) {
      return this.api.post('api/domain/glasses', params);
    }
    if (isShowExclusive) {
      return this.api.get('api/glasses', params);
    }
    return this.api.get('api/glasses', data);
  }

  getGlassesById(id) {
    return this.api.get('api/glasses/' + id);
  }

  deleteGlasses(ids) {
    return this.api.post('api/glasses/delete', {ids});
  }
  updateGlasses(id, params) {
    return this.api.put(`api/glasses/${id}`, params);
  }
  addGlasses(params) {
    return this.api.post(`api/glasses`, params);
  }
  importPim(params) {
    return this.api.post(`api/glasses/import`, params);
  }
  importStatus(params) {
    return this.api.post(`api/glasses/import-status`, params);
  }
  relatedGlassesToCustomers(params) {
    return this.api.post(`api/glasses/related-glasses-to-customers`, params);
  }
  setExclusiveBrand(params, add = false) {
    let url = 'api/glasses/re-exclusive';
    if (add) {
      url = 'api/glasses/exclusive';
    }
    return this.api.post(url, params);
  }
}
