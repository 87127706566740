import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin.routing';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import {
  NbContextMenuModule,
  NbMenuModule,
  NbSidebarModule,
  NbLayoutModule,
  NbIconModule,
  NbUserModule,
  NbSearchModule
} from '@nebular/theme';
import { HeaderComponent } from './components/header/header.component';
@NgModule({
  declarations: [AdminLayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    NbUserModule,
    NbSearchModule,
    AdminRoutingModule,
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbIconModule,
    NbLayoutModule,
    NbContextMenuModule,
  ]
})
export class AdminModule { }
