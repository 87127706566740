import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule, NbDialogModule,
  NbIconModule,
  NbLayoutModule,
  NbListModule, NbRouteTabsetModule
} from '@nebular/theme';
import { GlassesTableModule } from '../../../shared/components/glasses-table/glasses-table.module';
import { routing } from './glasses-routing.module';
import { GlassesComponent } from './glasses.component';



@NgModule({
  declarations: [
    GlassesComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    NbLayoutModule,
    NbListModule,
    NbCheckboxModule,
    NbDialogModule.forChild(),
    FormsModule,
    GlassesTableModule,
    routing,
    NbRouteTabsetModule
  ]
})
export class GlassesModule { }
