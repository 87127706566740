import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor( private api: ApiService) { }

  uploadFile(data) {
    return this.api.post('api/file-upload', data, true, true);
  }

  deleteFile(filesIds) {
    return this.api.post('api/file-upload/delete', {filesIds});
  }
}
