import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerRoutingModule } from './customer.routing';
import { CustomerLayoutComponent } from './components/customer-layout/customer-layout.component';
import {
  NbContextMenuModule,
  NbMenuModule,
  NbSidebarModule,
  NbLayoutModule,
  NbIconModule,
  NbUserModule,
  NbSearchModule,
  NbSelectModule,
  NbCardModule
} from '@nebular/theme';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule } from '@angular/forms';
import { NoProductsComponent } from './pages/no-products/no-products.component';
import { GlassesModule } from './pages/glasses/glasses.module';
import { SegmentsModule } from './pages/segments/segments.module';

@NgModule({
  declarations: [CustomerLayoutComponent, HeaderComponent, NoProductsComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbUserModule,
    NbSearchModule,
    CustomerRoutingModule,
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbIconModule,
    NbLayoutModule,
    NbContextMenuModule,
    NbSelectModule,
    FormsModule,
    GlassesModule,
    SegmentsModule
  ]
})
export class CustomerModule { }
