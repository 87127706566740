import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlassesComponent } from './glasses.component';


export const routes: Routes = [
  {
    path: '',
    component: GlassesComponent,
  },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
