import { Attachment } from './attachment.interface';

export enum CustomerDomainType {
    TERMINAL = 'terminal',
    WEBSITE = 'website',
    SUPER_DOMAIN = 'super_domain',
    SEGMENT = 'segment',
}
export class CustomerDomain {
  id?: number;
  type?: CustomerDomainType;
  license_id?: string;
  license?: Attachment;
  url?: string;
  show_3d_button?: boolean;
  allClicks?: number;
  monthClicks?: number;
  todayClicks?: number;
  weekClicks?: number;
  yearClicks?: number;
  logo?: Attachment;
  buttonType?: number;
  buttonColor?: string;
  settingsType?: CustomerDomainType;
  settings?: any;
  exclusive?: [];
}

export class CustomerDomainSegment {
  id?: number;
  name: string;
  company_name: string;
  country: string;
  reservation_url: string;
  url: string;
  license_id: string;
}
