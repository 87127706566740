import {UserRole} from '../enums/user-role.enum';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Role {
  currentUser = null;
  userRole = UserRole;

  constructor() {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  isAdmin() {
    return !!this.currentUser && this.currentUser.role === this.userRole.ADMIN;
  }

  isCustomer() {
    return !!this.currentUser && this.currentUser.role === this.userRole.CUSTOMER;
  }

  isDesigner() {
    return !!this.currentUser && this.currentUser.role === this.userRole.DESIGNER;
  }
}
