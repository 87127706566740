import {Observable} from 'rxjs';
import {debounceTime, map, mergeMap, startWith} from 'rxjs/operators';
import {Attachment} from '../../interfaces/attachment.interface';
import {Glasses, LinkingGlasses} from '../../interfaces/glasses.interface';
import {AttachmentType} from '../../enums/attachment-type.enum';
import {GlassesService} from '../../services/glasses.service';
import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbToastrService, NbDialogService, NbTagInputDirective, NbTagComponent} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import {UploadService} from '../../services/upload.service';
import {BrandsService} from '../../services/brands.service';
import {UploadFileComponent} from '../upload-file/upload-file.component';
import {HDRMapService} from '../../services/hdrmap.service';

@Component({
    selector: 'app-glasses-form',
    templateUrl: './glasses-form.component.html',
    styleUrls: ['./glasses-form.component.scss']
})
export class GlassesFormComponent implements OnInit, AfterViewInit {
    @ViewChild('first') first: any;
    @ViewChild(NbTagInputDirective, {read: ElementRef}) tagInput: ElementRef<HTMLInputElement>;

    @Input() glasses: Glasses;
    form = this.fb.group({
        ean: [''],
        brand: ['', [Validators.required]],
        sunglass: ['0'],
        normal_glass: ['0'],
        name_model: [''],
        glass_colour: this.fb.array([]),
        price: [''],
        SKU: ['', [Validators.required]],
        product_name: this.fb.array([], Validators.required),
        frame_shape: this.fb.array([]),
        frame_colour: this.fb.array([]),
        frame_material: [''],
        id: [''],
        glasses_files_ids: [''],
        front_image: [''],
        side_image: [''],
        slider: [null],
        linking_key: [null],
        default_hdrmap: [null],
    });

    linkingGlasses: LinkingGlasses[] = [];

    linkingGlassesOptions$: Observable<LinkingGlasses[]>;

    filteredBrandOptions$: Observable<string[]>;

    loading = false;
    frontImage: Attachment = null;
    sideImage: Attachment = null;
    modelArray: Attachment[] = [];
    glassesFilesIds = [];
    brandsList = [];
    currentBrandCode = null;
    linkingGlassesDisplay = 'none';
    hdrMapList = [];
    currentDefaultHDRMapCode = null;

    get multi_lang(): FormGroup {
        return this.fb.group({
            language: ['en'],
            body: ['']
        });
    }

    get id() {
        return this.form.get('id');
    }

    get product_name(): FormArray {
        return this.form.get('product_name') as FormArray;
    }

    get frame_shape(): FormArray {
        return this.form.get('frame_shape') as FormArray;
    }

    get frame_colour(): FormArray {
        return this.form.get('frame_colour') as FormArray;
    }

    get glass_colour(): FormArray {
        return this.form.get('glass_colour') as FormArray;
    }

    get fc() {
        return this.form.controls;
    }

    get linkingGlassesFiltered() {
        return this.linkingGlasses.filter(({id}) => id !== this.id.value);
    }

    constructor(protected dialogRef: NbDialogRef<GlassesFormComponent>,
                public fb: FormBuilder,
                private toastrService: NbToastrService,
                private glassesService: GlassesService,
                private uploadService: UploadService,
                private brandsService: BrandsService,
                protected dialogService: NbDialogService,
                public hdrmapService: HDRMapService,
                ) {
    }

    ngOnInit(): void {
        this.getAllBrands();
        this.getDefaultHDRMapList();
        if (this.glasses) {
            if (this.glasses?.photos?.length) {
                this.frontImage = this.glasses.photos[0];
                if (this.glasses.photos[1]) {
                    this.sideImage = this.glasses.photos[1];
                }
            }
            if (this.glasses?.modelObjects?.length) {
                this.modelArray = this.glasses.modelObjects;
            }
            if (this.glasses.product_name?.length) {
                for (const item of this.glasses.product_name) {
                    this.product_name.push(this.multi_lang);
                }
            } else {
                this.glasses.product_name = [];
                this.product_name.push(this.multi_lang);
            }
            if (this.glasses?.frame_shape?.length) {
                for (const item of this.glasses.frame_shape) {
                    this.frame_shape.push(this.multi_lang);
                }
            } else {
                this.glasses.frame_shape = [];
                this.frame_shape.push(this.multi_lang);
            }
            if (this.glasses?.frame_colour?.length) {
                for (const item of this.glasses.frame_colour) {
                    this.frame_colour.push(this.multi_lang);
                }
            } else {
                this.glasses.frame_colour = [];
                this.frame_colour.push(this.multi_lang);
            }
            if (this.glasses?.glass_colour?.length) {
                for (const item of this.glasses.glass_colour) {
                    this.glass_colour.push(this.multi_lang);
                }
            } else {
                this.glasses.glass_colour = [];
                this.glass_colour.push(this.multi_lang);
            }

            if (this.glasses?.frame_material) {
                this.glasses.frame_material = JSON.stringify(this.glasses.frame_material, null, '\t');
            }

            if (this.glasses?.relatedGlasses?.length) {
                this.linkingGlasses = this.glasses.relatedGlasses.map(el => ({id: el.id, name: el.name_model}));
            }

            this.form.patchValue(this.glasses);

            this.form.get('sunglass').setValue(this.glasses?.sunglass ? '1' : '0');
            this.form.get('normal_glass').setValue(this.glasses?.normal_glass ? '1' : '0');

        } else {
            this.product_name.push(this.multi_lang);
            this.frame_shape.push(this.multi_lang);
            this.frame_colour.push(this.multi_lang);
            this.glass_colour.push(this.multi_lang);
        }
        this.sliderEvents();
    }

    cancel() {
        this.dialogRef.close(false);
    }

    getAllBrands() {
        this.brandsService.getBrands({perPage: -1})
            .subscribe((response) => {
                this.brandsList = Object.keys(response.data).map(key => ({
                    ...response.data[key],
                    key,
                    value: response.data[key].name
                }));
                this.brandEvents();
                if (this.glasses?.brand_id) {
                    this.currentBrandCode = this.glasses.brand_id;
                }
            });
    }

    addModel() {
        this.dialogService.open(UploadFileComponent, {
            hasScroll: true,
            hasBackdrop: true,
            closeOnBackdropClick: true,
            context: {
                type: AttachmentType.GLASSES_MODEL,
                title: "Add glasses model",
                accept: ".glb",
                multiple: true
            }
        }).onClose.subscribe(data => {
            if (data?.length) {
                this.glassesFilesIds = this.glassesFilesIds.concat(data.map(el => el.id));
                this.modelArray = this.modelArray.concat(data);
            }
        });
    }

    clearField(field) {
        this.form.get(field).setValue('');
        this.currentBrandCode = null;
    }

    brandChanged(event) {
        if (event) {
            this.currentBrandCode = event;
        }
    }

    brandEvents() {
        this.filteredBrandOptions$ = this.fc.brand.valueChanges
            .pipe(
                startWith(''),
                map(filterString => this.brandFilter(filterString)),
            );
    }

    private brandFilter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const options = this.brandsList.filter(optionValue => optionValue.value.toLowerCase().includes(filterValue))
            .map(el => el.value);
        if (options?.length !== 1) {
            this.currentBrandCode = null;
        }
        return options;
    }

    addPhoto(type) {
        this.dialogService.open(UploadFileComponent, {
            hasBackdrop: true,
            closeOnBackdropClick: true,
            context: {
                type: AttachmentType.GLASSES_PHOTO,
                title: "Add glasses image",
                accept: "image/png, image/jpeg"
            }
        }).onClose.subscribe(data => {
            if (data) {
                this.form.get(type).setValue(data.id);
                this.form.get(type).markAsDirty();
                if (type === 'front_image') {
                    this.frontImage = data;
                } else {
                    this.sideImage = data;
                }
            }
        });
    }

    submit(value) {
        let params = Object.keys(value)
            .filter(key => this.form.controls[key].dirty)
            .reduce((obj, key) => {
                obj[key] = value[key] !== '' ? value[key] : null;
                return obj;
            }, {});
        if (params['frame_material']) {
            try {
                params['frame_material'] = JSON.parse(params['frame_material']);
            } catch (e) {
                this.toastrService.danger(null, 'Incorrect json format!');
                this.form.setErrors({frame_material: ['Incorrect json format!']});
                return;
            }
        }
        if (this.glassesFilesIds?.length) {
            params['glasses_files_ids'] = this.glassesFilesIds;
        } else {
            params['glasses_files_ids'] = [];
        }
        if (this.linkingGlasses?.length) {
            params['linking_glasses'] = this.linkingGlasses.map(el => el.id);
        } else {
            params['linking_glasses'] = [];
        }

        if (this.currentBrandCode) {
            params['brand_id'] = this.currentBrandCode;
        } else {
            params['brand_id'] = 0;
        }

        let newParams: any;
        newParams = {...params};

        delete params['slider'];
        this.changeGlasses(newParams);
    }

    changeGlasses(params) {
        this.loading = true;
        if (this.glasses?.id) {
            this.glassesService.updateGlasses(this.glasses.id, params).subscribe(() => {
                this.toastrService.success(null, 'Glasses updated');
                this.dialogRef.close(true);
            }, (errorResponse: HttpErrorResponse) => {
                this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
                this.loading = false;
                this.form.setErrors(errorResponse.error.errors);
            })
        } else {
            this.glassesService.addGlasses(params).subscribe(() => {
                this.toastrService.success(null, 'Glasses created');
                this.dialogRef.close(true);
            }, (errorResponse: HttpErrorResponse) => {
                this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
                this.loading = false;
                this.form.setErrors(errorResponse.error.errors);
            });
        }
    }

    deleteFile(index) {
        const fileId = this.modelArray[index].id;
        if (fileId) {
            this.uploadService.deleteFile([fileId]).subscribe(() => {
                this.glassesFilesIds = this.glassesFilesIds.filter(id => fileId !== id);
                this.modelArray.splice(index, 1);
                this.toastrService.success(null, 'File deleted!');
                this.form.setErrors(null);
            });
        }
    }

    deleteImgFile(type) {
        let fileId;

        if (type === 'front_image') {
            fileId = this.frontImage.id;
        } else {
            fileId = this.sideImage.id;
        }

        if (fileId) {
            this.uploadService.deleteFile([fileId]).subscribe(() => {
                this.form.get(type).setValue(null);
                this.form.get(type).markAsDirty();
                if (type === 'front_image') {
                    this.frontImage = null;
                } else {
                    this.sideImage = null;
                }
            });
        }
    }

    reformatMaterials() {
        let frame_material = this.form.get('frame_material').value;
        if (frame_material) {
            try {
                frame_material = JSON.parse(frame_material);
                frame_material = JSON.stringify(frame_material, null, '\t');
                this.form.get('frame_material').setValue(frame_material);
            } catch (e) {
                this.toastrService.danger(null, 'Incorrect json format!');
                this.form.setErrors({frame_material: ['Incorrect json format!']});
                return;
            }
        }
    }

    sliderEvents() {
        this.linkingGlassesOptions$ = this.fc.slider.valueChanges
            .pipe(
                debounceTime(300),
                //distinctUntilChanged(),
                startWith(''),
                mergeMap(filterString => this.glassesService
                    .getGlasses({
                        sortBy: 'name_model',
                        sortOrder: 'asc',
                        name_model: filterString,
                        without_glasses: this.linkingGlasses.map(el => el.id)
                    })),
                map(glassesData => {
                    return glassesData.data.map(el => ({id: el.id, name: el.name_model}));
                })
            );
    }


    onLinkingGlassesRemove(linkingGlassesToRemove: NbTagComponent): void {
        this.linkingGlasses = this.linkingGlasses.filter(el => el.name !== linkingGlassesToRemove.text);
    }

    onLinkingGlassesAdd(value): void {
        if (value) {
            this.linkingGlasses.push({id: value.id, name: value.name});
            this.fc.slider.setValue('');
        }
    }

    ngAfterViewInit() {
        setTimeout(() => this.linkingGlassesDisplay = 'inherit', 20);
        this.first.nativeElement.focus();
    }

    defaultHDRMapChanged(event) {
        if (event) {
            this.currentDefaultHDRMapCode = event;
        }
    }

    getDefaultHDRMapList() {
        this.hdrmapService.getHDRMaps({perPage: -1})
            .subscribe((response) => {
                this.hdrMapList = Object.keys(response.data).map(key => ({
                    ...response.data[key],
                    key,
                    value: response.data[key].name
                }));
                if (this.glasses?.default_hdrmap) {
                    this.currentDefaultHDRMapCode = this.glasses.default_hdrmap
                }
            });
    }
}
