import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(
    private api: ApiService
  ) { }
  restoreBrand(id) {
    return this.api.post(`api/brand/undelete`, {id})
  }
  deleteBrand(id, force = false) {
    if (force) {
      return this.api.post('api/brand/delete/' + id);
    }
    return this.api.delete('api/brands/' + id);
  }
  getBrands(params) {
    let data = new HttpParams();
    for(const key of Object.keys(params)) {
      data = data.append(key, params[key]);
    }
    return this.api.get('api/brands', data);
  }
  updateBrand(id, params) {
    return this.api.put(`api/brands/${id}`, params);
  }
  setExclusiveBrand(params, add = false) {
    let url = 'api/brand/re-exclusive';
    if (add) {
      url = 'api/brand/exclusive';
    }
    return this.api.post(url, params);
  }
  addBrand(params) {
    return this.api.post(`api/brands`, params);
  }
}
