import { GlassesService } from './../../shared/services/glasses.service';
import { User } from '../../shared/interfaces/user.interface';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GlassesResolverService implements Resolve<any> {

  constructor(private glassesService: GlassesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.glassesService.getGlassesById(route.paramMap.get('id'));
  }
}
