import { UsersService } from '../../services/users.service';
import { ValidationPatternEnum } from '../../enums/ validation-pattern.enum';
import { User } from '../../interfaces/user.interface';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators} from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormHelper } from '../../helpers/form.helper';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Input() user: User;
  form = this.fb.group({
    old_password: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.pattern(ValidationPatternEnum.PASSWORD)]],
    password_confirmation: ['', [Validators.required]]
  }, {validators: FormHelper.equalValidator});

  constructor(
    protected dialogRef: NbDialogRef<ChangePasswordComponent>,
    public fb: FormBuilder,
    public usersService: UsersService,
    private toastrService: NbToastrService
    ) { }

  ngOnInit(): void { }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    const params = Object.assign({}, this.form.value);
    this.usersService.changePassword(params).subscribe(data => {
      this.dialogRef.close(true);
    }, err => {
      this.toastrService.danger(null, err.error?.message ? err.error.message : 'Error');
      this.form.setErrors(err.error.errors);
    });
  }

}
