import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

  transform(value: any): string {
    if (value[0] && value[0].hasOwnProperty('body')) {
      if (value[0].body.length > 0) {
        return value[0].body ?? ''
      }
    }
    return '';
  }

}
