import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';


@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      filter((event: HttpEvent<any>) => (event instanceof HttpResponse)),
      map((data: HttpResponse<any>) => {
        let token = data.headers?.get('authorization');
        if (token) {
          token = token.replace('Bearer ', '');
          return data.clone({body: {token}});
        }
        return data;
      })
    );
  }
}
