import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {UsersService} from '../../services/users.service';
import {BrandsService} from '../../services/brands.service';
import { GlassesService } from '../../services/glasses.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-exclusive-brand',
  templateUrl: './exclusive-form.component.html',
  styleUrls: ['./exclusive-form.component.scss']
})
export class ExclusiveFormComponent implements OnInit {
  @Input() selectedItems = [];
  @Input() selectedCustomer = 0;
  @Input() selectedUrl = {};
  @Input() customers = [];
  @Input() urls = [];
  @Input() oneBrandName = {};
  @Input() isActionAdd = false;
  @Input() isBrands = true;
  @Input() isHaveCustomerWithUrl = false;
  @Input() action = null;
  loading = false;
  showOneBrandTitle = false;
  manyBrandTitle = '';
  selectedCustomerObj = {};
  selectedUrlObj = {};

  form = this.fb.group({
    selectedCustomer: [null, Validators.required],
    selectedUrl: [null, Validators.required],
    selectedItems: [[], Validators.required],
  });

  get fc() {
    return this.form.controls;
  }

  constructor(
    protected dialogRef: NbDialogRef<ExclusiveFormComponent>,
    private toastrService: NbToastrService,
    public fb: FormBuilder,
    public usersService: UsersService,
    public brandsService: BrandsService,
    public glassesService: GlassesService,
    protected dialogService: NbDialogService
  ) {
  }

  ngOnInit(): void {
    this.showOneBrandTitle = !!Object.keys(this.oneBrandName).length;
    const str = this.selectedItems.length > 3 ? `+ (${this.selectedItems.length - 3} pcs)` : '';
    this.manyBrandTitle = this.selectedItems.reduce((acc, elem, index) => {
      if (this.isBrands) {
        return acc + (index <= 3 ? `${elem.name}${(index !== 3 && index + 1 < this.selectedItems.length) ? ',' : ''} ` : '')
      }
      return acc + (index <= 3 ? `${elem.ean}${(index !== 3 && index + 1 < this.selectedItems.length) ? ',' : ''} ` : '')
    }, '') + str;
    this.selectedCustomerObj = this.customers.find(el => el.id === this.selectedCustomer);
    if (!this.urls.length && this.selectedCustomerObj) {
      this.urls = this.selectedCustomerObj['domains'];
    }
    if (this.urls.length) {
      this.selectedUrlObj = this.urls.find(el => el.id === this.selectedUrl);
    }
  }

  selectCustomerChange(event) {
    if (event) {
      this.urls = [];
      this.selectedUrl = null;
      this.loading = true;
      this.urls = this.customers.find(el => el.id === this.selectedCustomer)?.domains;
      this.loading = false;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    this.loading = true;
    if (this.isBrands) {
      this.submitBrands();
    } else {
      this.submitGlasses();
    }
  }

  submitBrands() {
    const body = {
      brands: this.selectedItems,
      domain_id: this.selectedUrl,
      customer_id: this.selectedCustomer,
    };
    this.brandsService
      .setExclusiveBrand(body, this.isActionAdd)
      .subscribe(() => {
        this.loading = false;
        this.toastrService.success(null, 'Successfully set exclusive');
        this.dialogRef.close(true);
      }, (errorResponse: HttpErrorResponse) => {
        this.loading = false;
        this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
      });
  }

  submitGlasses() {
    if (this.action) {
      let bodyBind = {};
      const glassesIds = Object.values(this.selectedItems).map((el) => el.id);
      if (this.action === 'bind') {
        bodyBind = {
          glassesIds,
          addToCustomersIds: [this.selectedCustomer],
          removeFromCustomerIds: [],
        };
      } else if (this.action === 'unbind') {
        bodyBind = {
          glassesIds,
          addToCustomersIds: [],
          removeFromCustomerIds: [this.selectedCustomer],
        };
      }

      this.loading = true;
      this.glassesService.relatedGlassesToCustomers(bodyBind)
        .subscribe(res => {
          this.toastrService.success(null, 'Glasses related successfully');
          this.loading = false;
          this.dialogRef.close(true);
        }, (errorResponse: HttpErrorResponse) => {
          this.loading = false;
          this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
        });
    } else {
      const body = {
        glasses: this.selectedItems,
        domain_id: this.selectedUrl,
        customer_id: this.selectedCustomer,
      };
      this.glassesService
        .setExclusiveBrand(body, this.isActionAdd)
        .subscribe(() => {
          this.loading = false;
          this.toastrService.success(null, 'Successfully set exclusive');
          this.dialogRef.close(true);
        }, (errorResponse: HttpErrorResponse) => {
          this.loading = false;
          this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
        });
    }
  }
}
