import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CustomerDomain } from '../../interfaces/customer-domain.interface';

@Component({
  selector: 'app-select-domains',
  templateUrl: './select-domains.component.html',
  styleUrls: ['./select-domains.component.scss']
})
export class SelectDomainsComponent implements OnInit {
  @Input() domains: CustomerDomain[] = [];
  @Input() presentInDomains = [];
  domainCheckBoxes = [];

  disabled = true;
  constructor(protected dialogRef: NbDialogRef<SelectDomainsComponent>) { }

  ngOnInit(): void {
    if (this.domains?.length) {
      this.domainCheckBoxes = this.domains.map(domain => ({
        id: domain.id,
        url: domain.url,
        checked: this.presentInDomains?.length && this.presentInDomains.includes(domain.id),
      }));
    }
  }

  check() {
    this.disabled = !(this.domainCheckBoxes.find(el => el.checked));
  }

  cancel() {
    this.dialogRef.close([]);
  }

  confirm() {
    this.dialogRef.close(this.domainCheckBoxes);
  }
}
