import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HDRMapService {

    constructor(
        private api: ApiService
    ) { }
    getHDRMaps(params) {
        let data = new HttpParams();
        for(const key of Object.keys(params)) {
            data = data.append(key, params[key]);
        }
        return this.api.get('api/HDRMapList', data);
    }
}
