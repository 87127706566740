import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SegmentsComponent } from './segments.component';


export const routes: Routes = [
  {
    path: '',
    component: SegmentsComponent,
  },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
