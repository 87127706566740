import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {GlassesTableComponent} from '../../../shared/components/glasses-table/glasses-table.component';
import {IconType} from '../../../shared/enums/icon-type.enum';
import {ConfirmComponent} from '../../../shared/modals/confirm/confirm.component';
import {SelectDomainsComponent} from '../../../shared/modals/select-domains/select-domains.component';
import {DomainsService} from '../../../shared/services/domains.service';
import {GlassesService} from '../../../shared/services/glasses.service';
import {UsersService} from '../../../shared/services/users.service';

@Component({
  selector: 'app-glasses',
  templateUrl: './glasses.component.html',
  styleUrls: ['./glasses.component.scss']
})
export class GlassesComponent implements OnInit, OnDestroy {
  @ViewChild(GlassesTableComponent, {static: false}) glassesComponent: GlassesTableComponent;
  subscriptions = [];
  selectedGlassesIds = [];
  superDomainMode = false;
  domainId = null;
  domains = [];
  columns = this.glassesService.getColumnsOfGlasses();
  columnsShow = [];
  iconType = IconType;

  filters = {
    sortBy: '',
    sortOrder: '',
    sunglass: '',
    normal_glass: '',
    page: 1,
    all: 0,
    is_exclusive: 0,
  };

  constructor(
    private userService: UsersService,
    private glassesService: GlassesService,
    private router: Router,
    private dialogService: NbDialogService,
    private domainsService: DomainsService,
    private toastrService: NbToastrService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.domainsService.domains.subscribe((domains) => {
        this.domains = domains || [];
    }));

    this.subscriptions.push(this.domainsService.superDomainMode.subscribe(superDomainMode => {
        this.superDomainMode = superDomainMode;
    }));

    this.subscriptions.push(this.domainsService.selectedDomainId.subscribe(id => {
        this.domainId = id;
    }));

    setTimeout(() => {
      this.columnsShow = this.columns.map(el => el.visible);
    }, 50);

    this.selectedGlassesIds = [];
  }

  onSelectRows(ids) {
    this.selectedGlassesIds = ids;
  }

  view(glasses) {
    if (glasses?.id) {
      this.router.navigate(['customer/glasses/view', glasses.id]);
    }
  }

  action(glasses) {
    if (glasses?.inDomainIds.includes(this.domainId)) {
      this.removeGlasses([glasses.id]);
    } else {
      this.openModalOne(glasses);
    }

  }

  addToDomainRows() {
    this.openModalMany(this.selectedGlassesIds);
  }

  showAddError(errorData) {
    if (errorData?.error?.length) {
      const messages = errorData.error.filter(el => el !== 'message');
      if (messages.length) {
        this.toastrService.danger(null, messages[0]);
      } else {
        this.toastrService.danger(null, 'Can\'t added glasses to domain');
      }
    } else if (errorData?.message) {
      this.toastrService.danger(null, errorData.message);
    }
  }

  openModalOne(glasses) {
    this.dialogService.open(SelectDomainsComponent, {
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {domains: this.domains, presentInDomains: glasses.inDomainIds}
    }).onClose.subscribe(data => {
      if (data?.length) {
        const addToDomainsIds = data.filter(domain => domain.checked).map(domain => domain.id);
        const removeFromDomainsIds = data.filter(domain => !domain.checked).map(domain => domain.id);
        const param = {addToDomainsIds, removeFromDomainsIds, glassesIds: [glasses.id]};

        this.selectedGlassesIds = [];
        this.domainsService.setGlasses(param).subscribe(res => {
          this.toastrService.success(null, 'Glasses updated successfully');
          this.glassesComponent.fetchGlasses();
        }, (err) => this.showAddError(err))
      }
    });
  }

  openModalMany(glassesIds) {
    this.dialogService.open(SelectDomainsComponent, {
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {domains: this.domains}
    }).onClose.subscribe(data => {
      if (data?.length) {
        const addToDomainsIds = data.filter(domain => domain.checked).map(domain => domain.id);
        const param = {addToDomainsIds, removeFromDomainsIds: [], glassesIds};

        this.selectedGlassesIds = [];
        this.domainsService.setGlasses(param).subscribe(res => {
          this.toastrService.success(null, 'Glasses attached successfully');
          this.glassesComponent.fetchGlasses();
        }, (err) => this.showAddError(err))
      }
    });
  }

  removeFromDomainRows() {
    this.removeGlasses(this.selectedGlassesIds);
  }

  removeGlasses(glassesIds) {
    this.dialogService.open(ConfirmComponent, {
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {
        message: `Are you sure want to detach ${glassesIds.length} glasses from current domain?`,
      }
    }).onClose.subscribe(res => {
      if (res) {
        const param = {addToDomainsIds: [], removeFromDomainsIds: [this.domainId], glassesIds};
        this.selectedGlassesIds = [];
        this.domainsService.setGlasses(param).subscribe(() => {
          this.toastrService.success(null, 'Successfully detached');
          this.glassesComponent.fetchGlasses();
        });
      }
    });
  }

  exportToCsv() {
    this.userService.getGlassesCsv(this.selectedGlassesIds);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscribe => {
      subscribe.unsubscribe();
    })
  }
}
