import { GlassesResolverService } from '../admin/resolver/glasses-resolver.service';
import { UserResolverService } from '../admin/resolver/user-resolver.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerAuthGuard } from '../shared/guards/customer-auth.guard';
import { CustomerLayoutComponent } from './components/customer-layout/customer-layout.component';
import { NoProductsComponent } from './pages/no-products/no-products.component';

const routes: Routes = [
  {
    path: '',
    component: CustomerLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'glasses'
      },
      {
        path: 'no-products',
        pathMatch: 'full',
        component: NoProductsComponent,
      },
      {
        path: 'statistics',
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
      },
      {
        path: 'glasses',
        canActivate: [CustomerAuthGuard],
        loadChildren: () => import('./pages/glasses/glasses.module').then(m => m.GlassesModule),
      },
      {
        path: 'glasses/view/:id',
        canActivate: [CustomerAuthGuard],
        resolve: {
          glasses: GlassesResolverService
        },
        loadChildren: () => import('../shared/components/glasses-info/glasses-info.module').then(m => m.GlassesInfoModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'how-to',
        loadChildren: () => import('./pages/how-to/how-to.module').then(m => m.HowToModule),
      },
      {
        path: 'segments',
        data: {hideSidebar: true},
        loadChildren: () => import('./pages/segments/segments.module').then(m => m.SegmentsModule),
      },
      {
        path: 'users/:id',
        data: {profile: true},
        resolve: {
          user: UserResolverService
        },
        loadChildren: () => import('../shared/components/user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule {
}
