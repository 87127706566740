import { Router } from '@angular/router';
import { NbMenuItem, NbSearchService } from '@nebular/theme';
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { UsersService } from '../../../shared/services/users.service';

import {PortalData} from '../../../shared/interfaces/portal-data.interface';

declare const portalData: PortalData;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggle = new EventEmitter<any>();
  @Input() profileMenu: NbMenuItem[];
  value;

  name = null;
  picture = null;

  portalData: PortalData = portalData;

  constructor(
    private searchService: NbSearchService,
    private router: Router,
    private usersService: UsersService
  ) {
    this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        this.value = data.term;
        this.router.navigate(['/admin/search-results', this.value])
      })
  }

  ngOnInit() {
    this.usersService.getCurrentUserObservable().subscribe(user => {
      this.name = user?.name;
      this.picture = user?.photo?.path;
    });
  }

  toggleSidebar() {
    this.toggle.emit();
  }
}
