import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AttachmentType } from '../../enums/attachment-type.enum';
import { UploadService } from '../../services/upload.service';
import { BrandsService } from '../../services/brands.service';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { CustomerDomain } from '../../interfaces/customer-domain.interface';
import { DomainsService } from '../../services/domains.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-domains',
  templateUrl: './add-domains.component.html',
  styleUrls: ['./add-domains.component.scss']
})
export class AddDomainsComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() domain: CustomerDomain;

  oldValue = 0;
  prevValue = 0;

  form = this.fb.group({
    type: ['website', Validators.required],
    url: ['', [Validators.required, Validators.pattern(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(\/)?$/)]],
    license_id: ['', Validators.required],
    license_file_id: [null, Validators.required],
    max_glass_count: ['', [
      Validators.required,
      Validators.min(1),
      Validators.pattern(/^(?![0\-\.])[0-9]*$/),
    ]],
    show_3d_button: [false],
    logo_file_id: [null],
    user_id: [''],
    id: [''],
    infinity: [false],
    exclusive: []
  });
  loading = false;

  fileLicenseName = '';
  logoFileName = '';
  brandsList = [];

  subscriptions: Subscription[] = [];

  get fc(){
    return this.form.controls;
  }

  constructor(protected dialogRef: NbDialogRef<AddDomainsComponent>,
              public fb: FormBuilder,
              public domainsService: DomainsService,
              private toastrService: NbToastrService,
              private uploadService: UploadService,
              private brandsService: BrandsService,
              protected dialogService: NbDialogService
              ) { }

  ngOnInit(): void {
    if (this.domain) {
      this.form.patchValue(this.domain);
      if (this.domain['max_glass_count'] <= -1) {
        document.getElementById('max_glass_count').setAttribute('type', 'text');
        this.form.controls.max_glass_count.setValue('infinity');
        this.form.controls.max_glass_count.disable();
        this.form.controls.max_glass_count.setValidators([
          Validators.required
        ]);
        this.form.controls.infinity.setValue(true);
      } else if (this.domain['max_glass_count'] === 0) {
        this.form.controls.max_glass_count.setValue('');
      }

      if (this.domain?.license?.id) {
        this.form.get('license_file_id').setValue(this.domain.license.id);
        this.fileLicenseName = this.domain.license?.name;
      }
      if (this.domain?.logo?.id) {
        this.form.get('logo_file_id').setValue(this.domain.logo.id);
        this.logoFileName = this.domain.logo?.name;
      }
    } else if (this.id) {
        this.form.get('user_id').setValue(this.id);
        this.form.get('user_id').markAsDirty();
    }

    if (!this.form.get('license_id').value) {
      this.updateApiKey();
    }

    this.subscriptions.push(this.fc.url.valueChanges
      .pipe(
        distinctUntilChanged(),
        map(val => val.toLowerCase())
      ).subscribe(newVal => {
        this.form.get('url').setValue(newVal);
      }));

    this.getBrandList();
  }

  getBrandList() {
    this.brandsService.getBrands({perPage: -1})
      .subscribe((response) => {
        this.brandsList = Object.keys(response.data).map(key => ({
          ...response.data[key],
          key,
          value: response.data[key].name
        }));
      });
  }
  cancel() {
    this.dialogRef.close(false);
  }

  submit(value) {
    this.loading = true;
    const params = Object.keys(value)
      .filter(key => this.form.controls[key].dirty)
      .reduce((obj, key) => {
        obj[key] = value[key] !== '' ? value[key] : null;
        return obj;
      }, {});
    if (!params['max_glass_count'] && this.prevValue === -1) {
      params['max_glass_count'] = this.prevValue;
    }
    if (value.id) {
      this.domainsService.updateDomain(value.id, params).subscribe(() => {
        this.toastrService.success(null, 'Domain updated');
        this.dialogRef.close(true);
      }, (errorResponse: HttpErrorResponse) => {
        this.toastrService.danger(null, errorResponse?.error?.message ? errorResponse.error.message : 'Error');
        this.loading = false;
        this.form.setErrors(errorResponse.error.errors);
      })
    } else {
      this.domainsService.addDomain(params).subscribe(() => {
        this.toastrService.success(null, 'Domain created');
        this.dialogRef.close(true);
      }, (errorResponse: HttpErrorResponse) => {
        this.toastrService.danger(null, errorResponse?.error?.message ? errorResponse.error.message : 'Error');
        this.loading = false;
        this.form.setErrors(errorResponse.error.errors);
      });
    }
  }

  addLicenseFile() {
    this.dialogService.open(UploadFileComponent, {
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {
        type: AttachmentType.DOMAIN_LICENSE,
        title: 'Add glasses license',
        accept: '.vlc'
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.form.get('license_file_id').setValue(data.id);
        this.form.get('license_file_id').markAsDirty();
        this.fileLicenseName = data.name;
      }
    });
  }

  addLogoFile() {
    this.dialogService.open(UploadFileComponent, {
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {
        type: AttachmentType.DOMAIN_LOGO,
        title: 'Add logo image',
        accept: 'image/png, image/jpeg'
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.form.get('logo_file_id').setValue(data.id);
        this.form.get('logo_file_id').markAsDirty();
        this.logoFileName = data.name;
      }
    });
  }

  deleteFile(type) {
    switch (type) {
      case 'license':
        const fileId = this.fc.license_file_id.value;
        if (fileId) {
          this.uploadService.deleteFile([fileId]).subscribe(() => {
            this.fileLicenseName = '';
            this.form.get('license_file_id').setValue('');
            this.toastrService.success(null, 'File deleted!');
          });
        }
        break;
      case 'logo':
      default:
        const fileLogoId = this.fc.logo_file_id.value;
        if (fileLogoId) {
          this.uploadService.deleteFile([fileLogoId]).subscribe(() => {
            this.logoFileName = '';
            this.form.get('logo_file_id').setValue('');
            this.toastrService.success(null, 'File deleted!');
          });
        }
    }
  }

  generateApiKey() {
    const passAt = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const passArray = Array.from({length: 29});

    return passArray.map((_, index) => {
      return index % 5 == 4 ? '-' : passAt.charAt(Math.random() * passAt.length)
    }).join('')
  }

  updateApiKey() {
    this.form.get('license_id').setValue(this.generateApiKey());
    this.form.get('license_id').markAsDirty();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  infinityChanged(e) {
    if (e.target.checked) {
      this.oldValue = this.form.controls.max_glass_count.value;
      this.form.controls.max_glass_count.setValidators([
        Validators.required
      ]);
      document.getElementById('max_glass_count').setAttribute('type', 'text');
      this.form.controls.max_glass_count.setValue('infinity');
      this.form.controls.max_glass_count.disable();
      this.prevValue = -1;
    } else {
      if (this.oldValue > 0) {
        this.form.controls.max_glass_count.setValue(this.oldValue)
      } else {
        this.form.controls.max_glass_count.setValue(null)
      }
      document.getElementById('max_glass_count').setAttribute('type', 'number');
      this.form.controls.max_glass_count.enable();
      this.form.controls.max_glass_count.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^(?![0\-\.])[0-9]*$/),
      ]);
      this.prevValue = 0;
    }
  }
  clearCache() {
    this.domainsService.clearCache(this.domain.id).subscribe(() => {
      this.toastrService.success(null, 'Domain updated');
      this.dialogRef.close(false);
    }, (errorResponse: HttpErrorResponse) => {
      this.toastrService.danger(null, errorResponse?.error?.message ? errorResponse.error.message : 'Error');
      this.loading = false;
      this.form.setErrors(errorResponse.error.errors);
    })
  }
}
