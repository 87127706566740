import { Component } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { UsersService } from './shared/services/users.service';

import {PortalData} from './shared/interfaces/portal-data.interface';

declare const portalData: PortalData;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tryme-portal';

  constructor(
    private authService: NbAuthService,
    private usersService: UsersService,
  ) {
    if (portalData.backendUrl === 'https://vto.liquifire.com' || portalData.backendUrl === 'https://vto.liquifire.test') {
        document.body.classList.add('theme-liquid');
        document.title = portalData.title;
    }

    this.authService.onAuthenticationChange().subscribe( isAuthenticated => {
      if (isAuthenticated) {
        this.usersService.getProfile().subscribe(user => {
          this.usersService.changeCurrentUser(user);
        });
      }
    });
  }
}
