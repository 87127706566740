import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {UsersService} from '../../services/users.service';
import {BrandsService} from '../../services/brands.service';
import {Brand} from '../../interfaces/brand.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {HDRMapService} from '../../services/hdrmap.service';

@Component({
    selector: 'app-brand-form',
    templateUrl: './brand-form.component.html',
    styleUrls: ['./brand-form.component.scss']
})
export class BrandFormComponent implements OnInit {
    @Input() brand: Brand;
    @Input() isNewBrand = false;
    loading = false;

    form = this.fb.group({
        id: [null],
        name: ['', Validators.required],
        default_hdrmap: [null],
    });

    hdrMapList = [];

    currentDefaultHDRMapCode = null;

    get fc() {
        return this.form.controls;
    }

    constructor(
        protected dialogRef: NbDialogRef<BrandFormComponent>,
        private toastrService: NbToastrService,
        public fb: FormBuilder,
        public usersService: UsersService,
        public brandsService: BrandsService,
        protected dialogService: NbDialogService,
        public hdrmapService: HDRMapService,
    ) {
    }

    ngOnInit(): void {
        this.form.patchValue(this.brand);
        this.getDefaultHDRMapList();
    }

    cancel() {
        this.dialogRef.close(false);
    }

    defaultHDRMapChanged(event) {
        if (event) {
            this.currentDefaultHDRMapCode = event;
        }
    }

    submit() {
        const params = Object.assign({}, this.form.value);
        let request;
        if (params?.id) {
            const id = params?.id;
            delete (params.id);
            Object.keys(params).forEach(key => {
                if (!this.form.get(key)?.dirty) {
                    delete (params[key]);
                }
            });
            if (!Object.keys(params).length) {
                this.toastrService.warning(null, 'Nothing to change');
                return;
            }
            request = this.brandsService.updateBrand(id, {...params, id});
        } else {
            delete (params.id);
            if (!Object.keys(params).length) {
                this.toastrService.warning(null, 'Nothing to change');
                return;
            }
            request = this.brandsService.addBrand(params);
        }
        this.loading = true;
        request.subscribe(() => {
            this.loading = false;
            this.dialogRef.close(true);
        }, (errorResponse: HttpErrorResponse) => {
            this.loading = false;
            this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
            this.form.setErrors(errorResponse.error.errors);
        });
    }

    getDefaultHDRMapList() {
        this.hdrmapService.getHDRMaps({perPage: -1})
            .subscribe((response) => {
                this.hdrMapList = Object.keys(response.data).map(key => ({
                    ...response.data[key],
                    key,
                    value: response.data[key].name
                }));
                if(this.brand?.default_hdrmap){
                    this.currentDefaultHDRMapCode = this.brand.default_hdrmap
                }
            });
    }
}
