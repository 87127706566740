import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {LocationsService} from '../../services/locations.service';
import { DomainsService } from '../../services/domains.service';
import { CustomerDomainSegment } from '../../interfaces/customer-domain.interface';

@Component({
  selector: 'app-segment-form',
  templateUrl: './segment-form.component.html',
  styleUrls: ['./segment-form.component.scss']
})
export class SegmentFormComponent implements OnInit, OnDestroy {
  @Input() segment: CustomerDomainSegment;
  loading = false;

  form = this.fb.group({
    id: [null],
    name: ['', Validators.required],
    company_name: ['', Validators.required],
    country: ['', Validators.required],
    reservation_url: ['', [Validators.required, Validators.pattern(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(\/)?$/)]],
    url: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9](\-{0,1}[A-Za-z0-9])*$/)]],
    license_id: ['', Validators.required],
  });

  countryOptions = [];

  filteredCountryOptions$: Observable<string[]>;
  currentCountryCode = null;

  subscriptions: Subscription[] = [];

  disabled: boolean;
  selectedValues: any;
  optionItems: {};
  access = [];
  brandsList = [];
  allBrandsList = [];

  get fc() {
    return this.form.controls;
  }

  constructor(
    protected dialogRef: NbDialogRef<SegmentFormComponent>,
    private toastrService: NbToastrService,
    private locationsService: LocationsService,
    public fb: FormBuilder,
    public domainsService: DomainsService,
  ) {
  }

  ngOnInit(): void {
    if (!this.segment?.id && !this.form.get('license_id').value) {
      this.updateApiKey();
    }

    if (this.segment?.id) {
        this.form.setValue(this.segment);
    }

    this.locationsService.getCountries().subscribe(res => {
      this.countryOptions = Object.keys(res).map(key => ({key, value: res[key]}));
      this.countryEvents();
    });
  }

  countryEvents() {
    this.filteredCountryOptions$ = this.fc.country.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.countryFilter(filterString)),
      );
  }

  private countryFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const options = this.countryOptions.filter(optionValue => optionValue.value.toLowerCase().includes(filterValue))
      .map(el => el.value);
    if (options?.length !== 1) {
      this.currentCountryCode = null;
    }
    return options;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    const params = Object.assign({}, this.form.value);
    let request;

    if (params?.id) {
      const id = params?.id;
      delete (params.id);
      Object.keys(params).forEach(key => {
        if (!this.form.get(key)?.dirty) {
          delete (params[key]);
        }
      });
      if (!Object.keys(params).length) {
        this.toastrService.warning(null, 'Nothing to change');
        return;
      }
      request = this.domainsService.updateSegment(id, params);
    } else {
      if (!Object.keys(params).length) {
        this.toastrService.warning(null, 'Nothing to change');
        return;
      }
      request = this.domainsService.addSegment(params);
    }
    this.loading = true;
    request.subscribe(() => {
      this.loading = false;
      this.dialogRef.close(true);
    }, (errorResponse: HttpErrorResponse) => {
      this.loading = false;
      this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
      this.form.setErrors(errorResponse.error.errors);
    });
  }

  countryChanged(event) {
    if (event?.length) {
      this.currentCountryCode = this.countryOptions.find(el => el.value === event)?.key;
    }
  }

  clearField(field) {
    this.form.get(field).setValue('');
    switch (field) { // d't use break to clear fields in a cascade
      case 'country':
        this.currentCountryCode = null;
        this.form.get('state').setValue('');
    }
  }

  generateApiKey() {
    const passAt = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const passArray = Array.from({length: 29});

    return passArray.map((_, index) => {
      return index % 5 == 4 ? '-' : passAt.charAt(Math.random() * passAt.length)
    }).join('')
  }

  nameChanged(event) {
    if (!this.form.get('url').touched && !this.segment?.id) {
        let slug = event.target.value.replaceAll(' ', '-').toLowerCase();

        this.form.get('url').setValue(slug);
    }
  }

  updateApiKey() {
    this.form.get('license_id').setValue(this.generateApiKey());
    this.form.get('license_id').markAsDirty();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
