import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IframeInterface } from '../interfaces/iframe-interface.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  private currentUser$ = new BehaviorSubject<IframeInterface>(null);

  constructor( private api: ApiService) { }

  getAllIframes(params) {
    return this.api.get('api/iframes', params);
  }

  getIframes() {
    return this.api.get('api/iframe/list');
  }

  setTemplate(params) {
    return this.api.put('api/iframes/update', params);
  }
}
