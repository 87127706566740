import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CustomerRouteType } from "../enums/customer-route-type.enum";
import { ProductsType } from "../enums/products-type.enum";
import { ApiService } from "./api.service";
import { Router } from "@angular/router";
import { UsersService } from "./users.service";
import {
    CustomerDomain,
    CustomerDomainSegment,
    CustomerDomainType,
} from "../interfaces/customer-domain.interface";
import { Collection } from "../interfaces/collection.interface";

@Injectable({
    providedIn: "root",
})
export class DomainsService {
    public domains: BehaviorSubject<CustomerDomain[]> = new BehaviorSubject(
        null
    );
    public selectedDomainId: BehaviorSubject<Number> = new BehaviorSubject(
        null
    );

    public superDomainMode: BehaviorSubject<boolean> = new BehaviorSubject(
        null
    );
    public segments: BehaviorSubject<CustomerDomainSegment[]> =
        new BehaviorSubject(null);
    public selectedSegmentId: BehaviorSubject<Number> = new BehaviorSubject(
        null
    );

    private currentDomain: CustomerDomain = null;

    constructor(
        private api: ApiService,
        private router: Router,
        private usersService: UsersService
    ) {
        this.usersService.getCurrentUserObservable().subscribe((user) => {
            if (user?.domains) {
                this.domains.next(user.domains);

                this.selectDomainId(
                    +localStorage.getItem("selectDomain") ||
                        user?.domains?.[0]?.id
                );
            }
        });

        this.selectedDomainId.subscribe((domainId) => {
            this.currentDomain = this.domains
                .getValue()
                ?.find(({ id }) => id === domainId);

            if (this.currentDomain) {
                if (
                    this.currentDomain.type === CustomerDomainType.SUPER_DOMAIN
                ) {
                    this.superDomainMode.next(true);
                    const segmentId =
                        +localStorage.getItem(
                            `selectSegmentForDomain'${this.currentDomain.id}'`
                        ) || null;
                    this.selectedSegmentId.next(segmentId);

                    if (segmentId) {
                        if (!this.segments.getValue()) {
                            this.getSegmentsList(segmentId).subscribe(
                                (segments) => {
                                    this.segments.next(segments);
                                }
                            );
                        }
                    }

                    if (!segmentId) {
                        this.router.navigate(["/customer/segments"]);
                    }
                } else {
                    this.superDomainMode.next(false);
                    this.selectedSegmentId.next(null);
                    this.segments.next(null);

                    if (this.router.url === "/customer/segments") {
                        this.router.navigate([
                            "/customer/glasses",
                        ]);
                    }
                }
            }
        });

        this.selectedSegmentId.subscribe((segmentId) => {
            if (segmentId) {
                if (!this.segments.getValue()) {
                    this.getSegmentsList(segmentId).subscribe((segments) => {
                        this.segments.next(segments);
                    });
                }
                if (this.router.url === "/customer/segments") {
                    this.router.navigate([
                        "/customer/glasses",
                    ]);
                }
            }
            if (
                !segmentId &&
                this.currentDomain?.type === CustomerDomainType.SUPER_DOMAIN
            ) {
                this.segments.next(null);
                this.router.navigate(["/customer/segments"]);
            }
        });
    }

    getColumnsOfSegments() {
        const columns = [
            { name: "Name", prop: "name", visible: true },
            { name: "Lookbook URL", prop: "url", visible: true },
            { name: "Company name", prop: "company_name", visible: true },
            { name: "Country", prop: "country", visible: true },
            { name: "Website URL", prop: "reservation_url", visible: true },
            { name: "Api key", prop: "license_id", visible: true },
        ];

        return columns;
    }

    selectDomainId(domainId: Number) {
        localStorage.setItem("selectDomain", `${domainId}`);
        this.selectedDomainId.next(domainId);
    }

    selectSegmentId(segmentId: Number) {
        localStorage.setItem(
            `selectSegmentForDomain'${this.selectedDomainId.getValue()}'`,
            `${segmentId}`
        );
        this.selectedSegmentId.next(segmentId);
    }

    getDomain(id):Observable<CustomerDomain> {
        return this.api.get("api/domains/" + id);
    }

    addDomain(params) {
        return this.api.post("api/domains", params);
    }

    updateDomain(id, params) {
        return this.api.put("api/domains/" + id, params);
    }

    deleteDomain(id) {
        return this.api.delete("api/domains/" + id);
    }

    resetDomainSettings(segment_id) {
        const domain_id = this.selectedDomainId.getValue();

        return this.api.patch(
            "api/domains/" + domain_id + "/segments/" + segment_id + '/resetSettings'
        );
    }

    getSegmentsList(id) {
        const data = this.api.getParams({ id });
        const domain_id = this.selectedDomainId.getValue();

        return this.api.get("api/domains/" + domain_id + "/segmentsList", data);
    }

    getSegments(params) {
        const data = this.api.getParams(params);
        const domain_id = this.selectedDomainId.getValue();

        return this.api.get("api/domains/" + domain_id + "/segments", data);
    }

    addSegment(params) {
        const domain_id = this.selectedDomainId.getValue();

        return this.api.post("api/domains/" + domain_id + "/segments", params);
    }

    updateSegment(segment_id, params) {
        const domain_id = this.selectedDomainId.getValue();

        return this.api.put(
            "api/domains/" + domain_id + "/segments/" + segment_id,
            params
        );
    }

    deleteSegment(segment_id) {
        const domain_id = this.selectedDomainId.getValue();

        return this.api.delete(
            "api/domains/" + domain_id + "/segments/" + segment_id
        );
    }

    setGlasses(params) {
        return this.api.post("api/domain/set-glasses", params);
    }

    setUrlHowToPage() {
        const id = localStorage.getItem("selectDomain");
        this.router.navigate([`/customer/how-to/${id}`]);
    }

    setUrlDomainsPage() {
        const id = localStorage.getItem("selectDomain");
        this.router.navigate([`/customer/domains/${id}`]);
    }

    getStatistic(type, userId = null, domainId = 0) {
        let url = `api/domains-statistic?event-type=${type}`;
        if (domainId) {
            url += "&domainId=" + domainId;
        }
        if (userId) {
            url += "&user_id=" + userId;
        }
        return this.api.get(url);
    }

    getProductTypeFromRouteType(routeType) {
        switch (routeType) {
            default:
            case CustomerRouteType.TRY_ON:
                return ProductsType.VIRTUAL_TRY_ON;
        }
    }

    getRouteTypeFromProductType(productsType) {
        switch (productsType) {
            default:
            case ProductsType.VIRTUAL_TRY_ON:
                return CustomerRouteType.TRY_ON;
        }
    }

    clearCache(domainId) {
        return this.api.get("api/domain/clear-cache/" + domainId);
    }
}
