import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from './segments-routing.module';
import { SegmentsComponent } from './segments.component';
import { NbCardModule, NbIconModule, NbDialogModule, NbButtonModule, NbCheckboxModule, NbInputModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    SegmentsComponent
  ],
  imports: [
    CommonModule,
    NbInputModule,
    NbSelectModule,
    NgxDatatableModule,
    NbTooltipModule,
    FormsModule,
    NbCheckboxModule,
    RouterModule,
    SharedModule,
    NbCardModule,
    NbDialogModule,
    NbIconModule,
    NbButtonModule,
    routing,
  ]
})
export class SegmentsModule { }
