import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerRouteType } from '../enums/customer-route-type.enum';
import { User } from '../interfaces/user.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private currentUser$ = new BehaviorSubject<User>(null);

  constructor( private api: ApiService) { }

  changeCurrentUser(user: User) {
    this.setCurrentUser(user);
    this.currentUser$.next(user);
  }

  getCurrentUserObservable() {
    return this.currentUser$.asObservable();
  }

  getCurrentUser() {
    if (window.localStorage?.currentUser) {
      return JSON.parse(window.localStorage.currentUser);
    }
    return null;
  }

  setCurrentUser(user) {
    window.localStorage.currentUser = JSON.stringify(user);
  }

  clearCurrentUser() {
    window.localStorage.removeItem('currentUser');
  }

  getUsers(params) {
    let data = new HttpParams();
    for(const key of Object.keys(params)) {
      data = data.append(key, params[key]);
    }
    return this.api.get('api/users', data);
  }
  getCustomerAll() {
    const params = {viewMode: 'customers'};
    let data = new HttpParams();
    for(const key of Object.keys(params)) {
      data = data.append(key, params[key]);
    }
    return this.api.get(`api/user/get-customer`, data)
  }

  getDefaultProduct() {
    return this.api.get(`api/user/default-product`)
  }

  getUser(id) {
    return this.api.get(`api/users/${id}`)
  }

  getProfile() {
    return this.api.post('/api/auth/me', {});
  }

  createUser(params) {
    return this.api.post('api/users', params);
  }

  updateUser(id, params) {
    return this.api.put('api/users/' + id, params);
  }

  deleteUser(id, force = false) {
    if (force) {
      return this.api.post('api/user/delete/' + id);
    }
    return this.api.delete('api/users/' + id);
  }

  restoreUser(id) {
    return this.api.post(`api/user/undelete`, {id})
  }

  resetPasswordProfile(params) {
    return this.api.post('api/auth/reset-password', params);
  }

  changePassword(params) {
    return this.api.post('api/auth/change-password', params);
  }

  resetPassword(token, params) {
    return this.api.post('api/auth/reset-password/' + token, params);
  }

  blockUser(params) {
    return this.api.post('api/auth/block', params);
  }

  unblockkUser(params) {
    return this.api.post('api/auth/unblock', params);
  }

  removeProduct(params) {
    return this.api.post('api/user/remove-product', params);
  }

  updateProduct(params) {
    return this.api.post('api/user/update-product', params);
  }

  getGlassesCsv(ids) {
    const idsStr = ids.join(',');

    this.api.download('api/get-glasses-csv?ids=' + idsStr).subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'glasses.csv';
        a.click();
        URL.revokeObjectURL(objectUrl);
      }
    )
  }

  getCustomerRouteTitle(type) {
    switch (type) {
      default:
      case CustomerRouteType.TRY_ON:
        return 'Virtual try on';
    }
  }
}
