import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {UsersService} from '../../services/users.service';
import {IframeTemplateInterface} from '../../interfaces/iframe-template-interface.interface';
import {IframeInterface} from '../../interfaces/iframe-interface.interface';
import {User} from '../../interfaces/user.interface';
import {IframeService} from '../../services/iframe.service';
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-add-template-iframe-form',
  templateUrl: './add-template-iframe-form.component.html',
  styleUrls: ['./add-template-iframe-form.component.scss']
})
export class AddTemplateIframeFormComponent implements OnInit {
  @Input() template: IframeTemplateInterface;
  @Input() customers: User[];
  @Input() iframes: IframeInterface[];
  loading = false;

  form = this.fb.group({
    id: [0, Validators.required],
    iframe_id: [0, Validators.required],
  });

  domain = '';

  get fc() {
    return this.form.controls;
  }

  constructor(
    protected dialogRef: NbDialogRef<AddTemplateIframeFormComponent>,
    private toastrService: NbToastrService,
    public fb: FormBuilder,
    public usersService: UsersService,
    protected dialogService: NbDialogService,
    protected iframeService: IframeService
  ) {
  }

  ngOnInit(): void {
    if (this.iframes.length) {
      this.form.get('iframe_id').setValue(this.template.iframe_id);
      this.form.get('id').setValue(this.template.id);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    const params = Object.assign({}, this.form.value);
    this.loading = true;
    this.iframeService.setTemplate(params).subscribe(() => {
      this.loading = false;
      this.dialogRef.close(true);
    }, (errorResponse: HttpErrorResponse) => {
      this.loading = false;
      this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
      this.form.setErrors(errorResponse.error.errors);
    });
  }
}
