import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import { ColumnMode, DatatableComponent, SelectionType } from "@swimlane/ngx-datatable";
import { Subject } from "rxjs";
import { map, debounceTime, distinctUntilChanged, mergeMap } from "rxjs/operators";
import { IconType } from "src/app/shared/enums/icon-type.enum";
import { Pagination } from "src/app/shared/helpers/pagination.helper";
import { Glasses } from "src/app/shared/interfaces/glasses.interface";
import { SegmentConfirmComponent } from "src/app/shared/modals/segment-confirm/segment-confirm.component";
import { SegmentFormComponent } from "src/app/shared/modals/segment-form/segment-form.component";
import { DomainsService } from "src/app/shared/services/domains.service";

@Component({
    selector: "app-segments",
    templateUrl: "./segments.component.html",
    styleUrls: ["./segments.component.scss"],
})
export class SegmentsComponent implements OnInit {
    @ViewChild(DatatableComponent) table: DatatableComponent;
    showFilter = false;
    columnsShow = [];
    search = null;
    actionIconType: IconType = IconType.ADMIN;
    domainIdFilter = false;
    selected = [];
    domainId = null;
    columns = [];
    readonly headerHeight = 90;
    readonly rowHeight = 52;
    loading = false;
    rows: Glasses[] = [];
    columnMode = ColumnMode;
    selectionType = SelectionType;

    page = {
        total: 0,
        current_page: 0,
        per_page: 15,
        from: 0,
        last_page: 0,
        to: 0,
    };

    filters = {
        sortBy: "",
        sortOrder: "",
        sunglass: "",
        normal_glass: "",
        page: 1,
        all: 0,
        show_exclusive: 0,
    };
    keyUp = new Subject<any>();

    iconType = IconType;

    constructor(
        private el: ElementRef,
        private route: ActivatedRoute,
        protected domainsService: DomainsService,
        public pagination: Pagination,
        private dialogService: NbDialogService
    ) {}

    ngOnInit(): void {
        this.columns = this.domainsService.getColumnsOfSegments();

        if (this.search) {
            this.filters["name_model"] = this.search;
        }
        this.page.current_page = this.route.snapshot.queryParams.page;
        this.keyUp
            .pipe(
                map((event) => event.target.value),
                debounceTime(300),
                distinctUntilChanged(),
                mergeMap((search) => {
                    const params = Object.assign(this.filters, { page: 1 });
                    this.loading = true;
                    this.pagination.changeUrl(params);
                    return this.domainsService.getSegments(params);
                })
            )
            .subscribe(this.parseData, (error) => {
                this.loading = false;
                setTimeout(() => {
                    this.table.recalculate();
                }, 50);
            });

        this.domainsService.selectedDomainId.subscribe((domainId) => {
            this.domainId = domainId;

            if (this.domainId) {
                this.filters = Object.assign(
                    this.filters,
                    this.route.snapshot.queryParams
                );
                this.getByPage();
            }
        });

        this.filters = Object.assign(
            this.filters,
            this.route.snapshot.queryParams
        );
        this.filters.sortBy = "";
        this.filters.sortOrder = "";
        this.filters.show_exclusive = +this.filters.show_exclusive;
        this.filters.all = +this.filters.all;
        this.pagination.changeUrl(this.filters);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ("domainId" in changes && this.domainIdFilter) {
            this.fetchGlasses();
        }

        if ("search" in changes) {
            if (this.search) {
                this.filters["brand"] = this.search;
            }
            this.fetchGlasses();
        }
        debugger;
    }

    setPage(e) {
        this.selected = [];
        if ("offset" in e) {
            this.filters.page = e.offset + 1;
            if (!e.offset) {
                const params = Object.assign(this.filters, {
                    page: e.offset + 1,
                });
                if (this.route.snapshot.queryParams.page) {
                    this.filters.page = this.route.snapshot.queryParams.page;
                }
            }
            this.getByPage();
        }
    }

    getByPage() {
        const params = Object.assign({}, this.filters);
        this.loading = true;
        this.pagination.changeUrl(params);

        this.domainsService
            .getSegments(params)
            .subscribe(this.parseData, (error) => {
                this.loading = false;
                this.table.recalculate();
            });
    }

    fetchGlasses() {
        this.selected = [];
        this.route.queryParams.subscribe((query) => {
            this.filters = { ...this.filters, ...query };
        });
        if (this.domainIdFilter && this.domainId < 1) {
            return;
        }
        this.loading = true;
        this.rows = [];

        const params = Object.assign(this.filters, { page: 1 });
        this.pagination.changeUrl(params);

        const getParams = Object.assign({}, params, {
            domainId: this.domainId,
        });

        this.domainsService
            .getSegments(params)
            .subscribe(this.parseData, (error) => {
                this.loading = false;
                this.table.recalculate();
            });
    }

    onSort(event) {
        if (event?.sorts?.length) {
            const params = event.sorts[0];
            this.filters = Object.assign(this.filters, {
                sortBy: params.prop,
                sortOrder: params.dir,
            });
            this.fetchGlasses();
        }
    }

    selectChange(event, prop) {
        this.pagination.changeUrl(this.filters);
        this.fetchGlasses();
    }

    onActivate(event) {
        if (event.type === "click") {
            if (event.column?.name !== "actions") {
                this.setSegment(event.row.id);
            }
        }
    }

    parseData = (data) => {
        this.loading = false;

        if (data?.data?.length) {
            this.rows = [...data.data];
        }

        if (data?.meta) {
            this.page = Object.assign({}, data.meta, {
                current_page: data.meta.current_page - 1,
            });
        }

        setTimeout(() => {
            this.table.recalculate();
        }, 50);
    };

    setSegment(id) {
        console.log("set");

        this.domainsService.selectSegmentId(id);
    }

    addSegment() {
        console.log("add");

        this.dialogService.open(SegmentFormComponent, {
            hasBackdrop: true,
        }).onClose.subscribe((res)=> {
            if (res) {
                this.fetchGlasses();
            }
        });
    }

    editSegment(segment) {
        console.log("edit");

        this.dialogService.open(SegmentFormComponent, {
            hasBackdrop: true,
            context: {
                segment,
            },
        }).onClose.subscribe((res)=> {
            if (res) {
                this.fetchGlasses();
            }
        });
    }

    deleteSegment(id) {
        console.log("delete");

        this.dialogService
            .open(SegmentConfirmComponent, {
                hasBackdrop: true,
                closeOnBackdropClick: true,
                context: {
                    message: `Are you sure to want delete this segment?`,
                    segmentId: id
                },
            })
            .onClose.subscribe((res) => {
                if (res) {
                    this.fetchGlasses();
                }
            });
    }
}
