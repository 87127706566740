import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges, SimpleChanges, ViewChild
} from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { IconType } from '../../enums/icon-type.enum';
import { Glasses } from '../../interfaces/glasses.interface';
import { GlassesService } from '../../services/glasses.service';
import {ActivatedRoute} from '@angular/router';
import {Pagination} from '../../helpers/pagination.helper';

@Component({
  selector: 'app-glasses-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './glasses-table.component.html',
  styleUrls: ['./glasses-table.component.scss']
})
export class GlassesTableComponent implements OnInit, OnChanges {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() columns = [];
  @Input() showFilter = false;
  @Input() columnsShow = [];
  @Input() search = null;
  @Input() actionIconType: IconType = IconType.ADMIN;// {icon: 'eye-outline', status: 'basic'};
  @Input() domainIdFilter = false;
  @Input() domainId = null;
  @Output() onCheck: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  @Output() totalResult: EventEmitter<any> = new EventEmitter();

  selected = [];

  readonly headerHeight = 90;
  readonly rowHeight = 52;
  loading = false;
  rows: Glasses[] = [];
  columnMode = ColumnMode;
  selectionType = SelectionType;
  page = {
    total: 0,
    current_page: 0,
    per_page: 15,
    from: 0,
    last_page: 0,
    to: 0
  };

  filters = {
    sortBy: '',
    sortOrder: '',
    sunglass: '',
    normal_glass: '',
    page: 1,
    all: 0,
    show_exclusive: 0
  };
  keyUp = new Subject<any>();

  iconType = IconType;

  parseData = data => {
    this.loading = false;
    if (data?.data.length) {
      this.rows = [...data.data];
    }

    if (data?.meta) {
      this.page = Object.assign({}, data.meta, {current_page: data.meta.current_page - 1});
      this.totalResult.emit(this.page.total);
    }
    setTimeout(() => {
      this.table.recalculate();
    }, 50);
  };

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    protected glassesService: GlassesService,
    public pagination: Pagination,
  ) {}

  ngOnInit(): void {
    if (this.search) {
      this.filters['name_model'] = this.search;
    }
    this.page.current_page = this.route.snapshot.queryParams.page;
    this.keyUp.pipe(
      map(event => event.target.value),
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap(search => {
        const params = Object.assign(this.filters, {page: 1}, {domainId: this.domainId});
        this.loading = true;
        this.pagination.changeUrl(params)
        return this.glassesService.getGlasses(params);
      }),
    ).subscribe(this.parseData, error => {
        this.loading = false;
        setTimeout(() => {
          this.table.recalculate();
        }, 50);
    });
    if (this.domainId < 1) {
      this.filters = Object.assign(this.filters, this.route.snapshot.queryParams);
      this.getByPage();
    }

    this.filters = Object.assign(this.filters, this.route.snapshot.queryParams);
    this.filters.sortBy = '';
    this.filters.sortOrder = '';
    this.filters.show_exclusive = +this.filters.show_exclusive;
    this.filters.all = +this.filters.all;
    this.pagination.changeUrl(this.filters);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('domainId' in changes && this.domainIdFilter) {
        this.fetchGlasses();
    }

    if ('search' in changes) {
      if (this.search) {
        this.filters['brand'] = this.search;
      }
      this.fetchGlasses();
    }
  }

  setPage(e) {
    this.selected = [];
    if ('offset' in e) {
      this.filters.page = e.offset + 1;
      if (!e.offset) {
        const params = Object.assign(this.filters, { page: e.offset + 1 });
        if (this.route.snapshot.queryParams.page) {
          this.filters.page = this.route.snapshot.queryParams.page;
        }
      }
      this.getByPage();
    }
  }

  getByPage() {
    const params = Object.assign({}, this.filters, { domainId: this.domainId });
    this.loading = true;
    this.pagination.changeUrl(params);
    this.glassesService.getGlasses(params).subscribe(this.parseData, error => {
      this.loading = false;
      this.table.recalculate();
    });
  }

  fetchGlasses() {
    this.selected = [];
    this.route.queryParams.subscribe((query) => {
      this.filters = { ...this.filters, ...query };
    });
    if (this.domainIdFilter && this.domainId < 1) {
      return;
    }
    this.loading = true;
    this.rows = [];

    const params = Object.assign(this.filters, {page: 1});
    this.pagination.changeUrl(params);

    const getParams = Object.assign({}, params, { domainId: this.domainId });
    this.glassesService.getGlasses(getParams).subscribe(this.parseData, error => {
      this.loading = false;
      this.table.recalculate();
    });
  }

  onSort(event) {
    if (event?.sorts?.length) {
      const params = event.sorts[0];
      this.filters = Object.assign(this.filters, {
        sortBy: params.prop,
        sortOrder: params.dir,
      });
      this.fetchGlasses();
    }
  }

  getPriceClass({ row, column, value }): any {
    return {
      price: column.prop === 'price'
    };
  }

  onSelect({ selected }) {
    this.pagination.changeUrl(this.filters);
    this.selected.splice(0, this.selected.length);
    if (selected?.length) {
      this.selected.push(...selected);
    }
    this.onCheck.emit(this.selected.map(el => el.id));
  }

  onActivate(event) {
    if (event.type === 'click') {
      if (event.column?.headerClass === 'checkbox-header-cell') {
        return;
      }
      if (!event?.row?.id) {
        return;
      }
      if (event.column?.frozenRight) {
        this.onAction.emit(event.row);
        return;
      }
      this.onClick.emit(event.row);
    }
  }
  selectChange(event, prop) {
    this.pagination.changeUrl(this.filters);
    this.fetchGlasses();
  }

  toggleCheckbox(e) {
    if (this.filters.all && e) {
      this.filters = Object.assign(this.filters, { all: 0 });
    }
    this.filters = Object.assign(this.filters, { show_exclusive: +e });
    this.pagination.changeUrl(this.filters);

    this.loading = true;
    this.rows = [];
    const params = Object.assign(this.filters, {page: 1}, {domainId: this.domainId});
    this.glassesService.getGlasses(params).subscribe(this.parseData, error => {
      this.loading = false;
      this.table.recalculate();
    });
  }

  toggleCheckboxAll(e) {
    if (this.filters.show_exclusive && e) {
      this.filters = Object.assign(this.filters, { show_exclusive: 0 });
    }
    this.filters = Object.assign(this.filters, { all: +e });
    this.pagination.changeUrl(this.filters);

    this.loading = true;
    this.rows = [];
    const params = Object.assign(this.filters, {page: 1}, { domainId: this.domainId });
    this.glassesService.getGlasses(params).subscribe(this.parseData, error => {
      this.loading = false;
      this.table.recalculate();
    });
  }
}
