import {
    Component,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { EventEmitter } from "@angular/core";
import { SettingType } from "../../enums/setting-type.enum";
import { CustomerDomain } from "../../interfaces/customer-domain.interface";
import { ApiService } from "../../services/api.service";
import { DomainsService } from "../../services/domains.service";
import { UploadService } from "../../services/upload.service";

@Component({
    selector: "app-settings-form",
    templateUrl: "./settings-form.component.html",
    styleUrls: ["./settings-form.component.scss"],
})
export class SettingsFormComponent implements OnInit, OnChanges {
    @Input() domain: CustomerDomain = null;
    @Input() settingType: SettingType = null;
    @Output() settingChanged = new EventEmitter<boolean>();

    selectedButton = "1";
    show3dButton = false;
    settings = {
        color: "",
        colorBg: "",
        customText: "TryMe",
        customHeight: 50,
        customHeightUnits: "px",
        customWidth: 150,
        customWidthUnits: "px",
        customRadius: 0,
        fontSize: 12,
        letterSpacing: 1,
        fontWeight: "normal",
        fontFamily: "",
        hideIPD: false,
        cornerHeight: 20,
        reservationButtonText: "",
        reservationButtonMethod: "redirect",
        reservationRelativePath: "",
        reservationModelInputName: "",
    };

    constructor(
        private apiService: ApiService,
        private uploadService: UploadService,
        private domainsService: DomainsService
    ) {}

    ngOnInit(): void {
        const corner =
            this.settings.cornerHeight > 30
                ? this.settings.cornerHeight - 20
                : this.settings.cornerHeight - 10;
        document.documentElement.style.setProperty(
            "--dynamic-height",
            "-" + this.settings.cornerHeight + "px"
        );
        document.documentElement.style.setProperty(
            "--dynamic-border-top",
            this.settings.cornerHeight + "px solid rgba(0, 0, 0, 0"
        );
        document.documentElement.style.setProperty(
            "--dynamic-border-left",
            corner + "px solid " + this.settings.colorBg
        );
        document.documentElement.style.setProperty(
            "--dynamic-color",
            this.settings.colorBg
        );

        if (this.domain?.show_3d_button) {
            this.settings["customHeight"] = 150;
            this.settings["customWidth"] = 150;
            this.settings["customRadius"] = 12;
            this.settings["cornerHeight"] = 20;
            if (this.domain?.settings[this.settingType]) {
                for (const key of Object.keys(this.settings)) {
                    if (
                        key in this.domain.settings[this.settingType].settings
                    ) {
                        this.settings[key] =
                            this.domain.settings[this.settingType].settings[
                                key
                            ];
                    }
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.show3dButton = this.domain?.show_3d_button;
        setTimeout(() => {
            this.settingChanged.emit(false);
        }, 100);

        if (this.domain?.settings) {
            this.selectedButton = this.domain?.settings[this.settingType]
                ?.buttonType
                ? "" + this.domain.settings[this.settingType].buttonType
                : "1";
        }

        if (
            this.domain?.settings &&
            this.domain?.settings[this.settingType]?.settings
        ) {
            for (const key of Object.keys(this.settings)) {
                if (key in this.domain.settings[this.settingType].settings) {
                    this.settings[key] =
                        this.domain.settings[this.settingType].settings[key];
                }
            }
        }
    }

    save() {
        this.settingChanged.emit(false);
        if (this.domain?.id) {
            return this.domainsService
                .updateDomain(this.domain.id, {
                    setting_type: this.settingType,
                    settings: this.settings,
                    button_type: this.selectedButton,
                });
        }
    }

    formChanged() {
        this.settingChanged.emit(true);

        const corner =
            this.settings.cornerHeight > 30
                ? this.settings.cornerHeight - 20
                : this.settings.cornerHeight - 10;
        document.documentElement.style.setProperty(
            "--dynamic-height",
            "-" + this.settings.cornerHeight + "px"
        );
        document.documentElement.style.setProperty(
            "--dynamic-border-top",
            this.settings.cornerHeight + "px solid rgba(0, 0, 0, 0)"
        );
        document.documentElement.style.setProperty(
            "--dynamic-border-left",
            corner + "px solid " + this.settings.colorBg
        );
        document.documentElement.style.setProperty(
            "--dynamic-color",
            this.settings.colorBg
        );
    }

    onReserverationButtonChange(event: any) {
        this.settingChanged.emit(true);

        this.settings = {
            ...this.settings,
            [event.target.name]: event.target.value,
        };
    }

    onReserverationButtonMethodChange(value: any) {
        this.settingChanged.emit(true);

        if (this.settings.reservationButtonMethod === 'event') {
            this.settings.reservationRelativePath = "";
            this.settings.reservationModelInputName = "";
        }
    }
}
