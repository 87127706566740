import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Pagination {
  constructor(
    private router: Router,
    private location: Location,
  ) { }

  changeUrl(filters) {
    const query = Object.keys(filters).reduce((acc, el) => {
      if (acc !== '' && filters[el] !== '') {
        acc += '&'
      }
      return filters[el] !== '' ? `${acc}${el}=${encodeURIComponent(filters[el])}` : acc;
    }, '')
    const url = this.router.url.split('?')[0];
    this.location.go(`${url}${query ? '?' + query : '?page=' + filters.page }`)
  }

  setDomainId() {
    const id = localStorage.getItem('selectDomain');
    const url = this.router;
    const currentUrl = url.url.split('?')[0];
    const browserUrlTree = this.router["browserUrlTree"];
    this.router.navigate([`${currentUrl.split('/').slice(0, -1).join('/')}/${id}`]);

  }
}
