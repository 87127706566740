import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, last, map } from 'rxjs/operators';
import { DateSafePipe } from '../pipes/dateSafe.pipe';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    public dateSafePipe: DateSafePipe,
  ) { }

  private formatErrors(error: any) {
    if (+error.status === 401) {
      if (localStorage.getItem('auth_app_token')) {
        localStorage.removeItem('auth_app_token')
      }
      if (localStorage.getItem('currentUser')) {
        localStorage.removeItem('currentUser')
      }
      window.location.href = '/auth/login'
    }
    return observableThrowError(error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(path, {params})
      .pipe(catchError(this.formatErrors));
  }

  download(path: string): Observable<Blob> {
    return this.http.get(path, { responseType: 'blob' });
  }

  put(path: string, body: Object | FormData = {}, upload: boolean = false): Observable<any> {
    if (upload && body instanceof FormData) {
      return this.request('PUT', path, body, {reportProgress: true});
    }
    return this.http.put(
      path,
      JSON.stringify(body),
      {headers : new HttpHeaders({ 'Content-Type': 'application/json' }),}
    ).pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(
      path,
      JSON.stringify(body),
      {headers : new HttpHeaders({ 'Content-Type': 'application/json' }),}
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object | FormData = {}, upload: boolean = false, progress: boolean = false): Observable<any> {
    if (body instanceof FormData) {
      if (upload && progress) {
        return this.http.post<any>(path, body, {
          reportProgress: true,
          observe: 'events'
        }).pipe(
          map((event) => {
            if (event.type === HttpEventType.Response) {
              return event.body;
            } else if (event.type === HttpEventType.UploadProgress) {
              return {progress: Math.floor(event.loaded / event.total * 100)};
            }

            return null;
          }),
          catchError(this.formatErrors)
        );
      } else if (upload) {
        return this.request('POST', path, body, {reportProgress: true});
      }
    }

    return this.http.post(
      path,
      JSON.stringify(body),
      {headers : new HttpHeaders({ 'Content-Type': 'application/json' }),}
    ).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(path).pipe(catchError(this.formatErrors));
  }

  request(method, path, data: FormData, options: Object = {}): Observable<any> {
    const req = new HttpRequest(method, path, data, options);
    return this.http.request(req)
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        }),
        last(), // return last (completed) message to caller
        catchError(this.formatErrors)
      );
  }

  public createFormData(object: Object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    Object.keys(object).forEach(property => {
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date && object[property] !== 'Invalid Date') {
        formData.append(formKey, this.dateSafePipe.transform(object[property], 'yyyy-MM-dd', 'en-US'));
      } else if (typeof object[property] === 'object' && !(object[property] instanceof File) && !(object[property] === null)) {
        this.createFormData(object[property], formData, formKey);
      } else if (object[property] === null) {
        formData.append(formKey, '');
      } else {
        formData.append(formKey, object[property]);
      }
    });

    return formData;
  }

  public getParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}[]`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      }
    }
    return params;
  }

}
