import {Product} from '../../interfaces/products.interface';
import {UsersService} from '../../services/users.service';
import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import {User} from '../../interfaces/user.interface';

@Component({
  selector: 'app-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.scss']
})
export class AddProductsComponent implements OnInit {
  @Input() products: Product[];
  @Input() user: User;

  virtualTryOn = true;
  loading = false;

  constructor(
    protected dialogRef: NbDialogRef<AddProductsComponent>,
    private toastrService: NbToastrService,
    private usersService: UsersService,
  ) {
  }

  ngOnInit(): void {
    this.virtualTryOn = this.user.access_to_products['virtual_try_on']['value'];
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    this.loading = true;
    const params = {
      user_id: this.user.id,
      virtual_try_on: this.virtualTryOn
    }

    this.usersService.updateProduct(params).subscribe(() => {
      this.toastrService.success(null, 'Product access updated');
      this.dialogRef.close(true);
    }, (errorResponse: HttpErrorResponse) => {
      this.toastrService.danger(null, errorResponse?.error?.message ? errorResponse.error.message : 'Error');
      this.loading = false;
    })
  }
}
