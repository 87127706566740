import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbUserModule,
  NbSpinnerModule,
  NbSelectModule,
  NbAutocompleteModule,
  NbRadioModule,
  NbTagModule,
  NbBadgeModule,
  NbTooltipModule,
  NbToggleModule,
} from '@nebular/theme';
import { ColorPickerModule } from 'ngx-color-picker';
import { SettingsFormComponent } from './components/settings-form/settings-form.component';
import { ConfirmDeleteComponent } from './modals/confirm-delete/confirm-delete.component';
import { CustomerFormComponent } from './modals/customer-form/customer-form.component';
import { SegmentFormComponent } from './modals/segment-form/segment-form.component';
import { AddDomainsComponent } from './modals/add-domains/add-domains.component';
import { AddProductsComponent } from './modals/add-products/add-products.component';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { ChangePasswordComponent } from './modals/change-password/change-password.component';
import { SelectDomainsComponent } from './modals/select-domains/select-domains.component';
import { SelectCustomersComponent } from './modals/select-customers/select-customers.component';
import { DateSafePipe } from './pipes/dateSafe.pipe';
import { GlassesFormComponent } from './modals/glasses-form/glasses-form.component';
import { SegmentConfirmComponent } from './modals/segment-confirm/segment-confirm.component';
import { UploadFileComponent } from './modals/upload-file/upload-file.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DomainPipe } from './pipes/domain.pipe';
import { YesNoPipe } from './pipes/yesNo.pipe';
import {BrandFormComponent} from './modals/brand-form/brand-form.component';
import {ExclusiveFormComponent} from './modals/exclusive-form/exclusive-form.component';
import {AddTemplateIframeFormComponent} from './modals/add-template-iframe/add-template-iframe-form.component';
import { GlassesPhotoPipe } from './pipes/glasses-photo.pipe';
import { ProductNamePipe } from './pipes/product-name.pipe';

const components = [
  DateSafePipe,
  DomainPipe,
  YesNoPipe,
  GlassesPhotoPipe,
  ProductNamePipe,
  CustomerFormComponent,
  SegmentFormComponent,
  AddDomainsComponent,
  AddProductsComponent,
  AddTemplateIframeFormComponent,
  ConfirmComponent,
  SegmentConfirmComponent,
  ConfirmDeleteComponent,
  ChangePasswordComponent,
  SelectDomainsComponent,
  SelectCustomersComponent,
  GlassesFormComponent,
  BrandFormComponent,
  UploadFileComponent,
  SettingsFormComponent,
  ExclusiveFormComponent
];

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbInputModule,
    NbFormFieldModule,
    NbIconModule,
    NbButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NbUserModule,
    NbListModule,
    NbCheckboxModule,
    NbSpinnerModule,
    NgxFileDropModule,
    NbSelectModule,
    NbAutocompleteModule,
    NbRadioModule,
    NbTagModule,
    NbBadgeModule,
    NbTooltipModule,
    ColorPickerModule,
    NbToggleModule,
  ],
  declarations: components,
  exports: components,
  providers: [
    DateSafePipe,
    DomainPipe,
    YesNoPipe,
    GlassesPhotoPipe,
    ProductNamePipe
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
