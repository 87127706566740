import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'glassesPhoto'
})
export class GlassesPhotoPipe implements PipeTransform {

  transform(value: Array<any>): string {
    return value[0]?.path ?? '/assets/images/default-glasses.png';
  }

}
