import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CustomerRouteType } from '../enums/customer-route-type.enum';
import { AccessToProduct } from '../interfaces/access-to-product.interface';
import { DomainsService } from '../services/domains.service';
import { UsersService } from '../services/users.service';

@Injectable()
export class CustomerAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private domainsService: DomainsService,
    private usersService: UsersService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    const user = this.usersService.getCurrentUser();

    if (!user) {
      this.router.navigate(['auth/login']);
      return false;
    }

    if (!user?.access_to_products) {
      this.router.navigate(['customer/no-products']);
      return false;
    }

    const path = route.url[0].path;
    const accessRoutTypeList: string[] = Object.values(user.access_to_products)
      .filter((accessToProduct: AccessToProduct) => accessToProduct.value)
      .map((accessToProduct: AccessToProduct) => this.domainsService.getRouteTypeFromProductType(accessToProduct.key));

    if (!accessRoutTypeList?.length) {
      this.router.navigate(['customer/no-products']);
      return false;
    }
    if (['domains', 'glasses'].includes(path)) {
      if (!accessRoutTypeList.includes(CustomerRouteType.TRY_ON)) {
        this.router.navigate(['customer', 'statistics', accessRoutTypeList[0]]);
        return false;
      }
      return true;
    }

    // TODO: Remove when you review logic

    // const urlArray = state.url.split('/').filter(el => el.length);
    // if (!accessRoutTypeList.includes(path)) {
    //   this.router.navigate(['customer', urlArray[1], accessRoutTypeList[0]]);
    //   return false;
    // }
    return true;
  }
}
