import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DeleteType } from '../../enums/delete-type.enum';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent {
  @Input() message: string;
  @Input() showHard = true;
  deleteType = DeleteType;

  constructor(protected dialogRef: NbDialogRef<ConfirmDeleteComponent>) { }

  action(type: DeleteType) {
    this.dialogRef.close(type);
  }
}
