import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserRole } from '../enums/user-role.enum';
import { UsersService } from '../services/users.service';

@Injectable()
export class RootAuthGuard implements CanActivate {

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private usersService: UsersService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        take(1),
        map(authenticated => {
          const user = this.usersService.getCurrentUser();
          if (!authenticated) {
            this.router.navigate(['auth/login']);
            return false;
          }
          if (!user) {
            this.router.navigate(['auth/login']);
            return false;
          }
          if (route.routeConfig.path === user?.role) {
            return true;
          }
          switch (user?.role) {
            case UserRole.ADMIN:
              this.router.navigate(['admin']);
              return false;
            case UserRole.CUSTOMER:
              this.router.navigate(['customer']);
              return false;
            case UserRole.DESIGNER:
              this.router.navigate(['designer']);
              return false;
          }

          return true;
        })
      );
  }
}
