import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {AttachmentType} from '../../enums/attachment-type.enum';
import {UserRole} from '../../enums/user-role.enum';
import {User} from '../../interfaces/user.interface';
import {LocationsService} from '../../services/locations.service';
import {UploadService} from '../../services/upload.service';
import {UsersService} from '../../services/users.service';
import {UploadFileComponent} from '../upload-file/upload-file.component';
import {Role} from '../../helpers/role.helper';
import {BrandsService} from '../../services/brands.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  @Input() customer: User;
  @Input() isMyProfile = false;
  loading = false;
  isAdmin = false;
  currencySelected = '';

  userRole = UserRole;

  oldValue = 0;
  prevValue = 0;

  form = this.fb.group({
    id: [null],
    company_name: ['', []],
    zip: ['', []],
    country: ['', []],
    city: ['', []],
    state: ['', []],
    address: ['', []],
    address1: ['', []],
    tax_id: ['', []],
    company_number_kvk: ['', []],
    currency: ['', []],
    bank_name: ['', []],
    bank_account_number: ['', []],
    contact_person: ['', []],
    phone: ['', []],
    role: [this.userRole.CUSTOMER, [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    website: ['', [Validators.required, Validators.pattern(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(\/)?$/)]],
    name: ['', Validators.required],
    type: ['website', Validators.required],
    max_glass_count: ['', [
      Validators.required,
      Validators.min(1),
      Validators.pattern(/^(?![0\-\.])[0-9]*$/),
    ]],
    url: ['',
      [
        Validators.required,
        Validators.pattern(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(\/)?$/)
      ]
    ],
    license_id: [''],
    file_license_id: [null],
    logo_file_id: [null],
    access: [[]],
    bind: [[]],
    exclusive: [[]],
    add_new_brands: [false],
  });

  fileLicenseName = '';
  logoFileName = '';


  countryOptions = [];
  stateOptions = [];
  cityOptions = [];

  filteredCountryOptions$: Observable<string[]>;
  currentCountryCode = null;

  filteredStateOptions$: Observable<string[]>;
  currentStateCode = null;

  filteredCityOptions$: Observable<string[]>;

  subscriptions: Subscription[] = [];

  disabled: boolean;
  selectedValues: any;
  optionItems: {};
  access = [];
  brandsList = [];
  allBrandsList = [];

  get fc() {
    return this.form.controls;
  }

  constructor(
    protected dialogRef: NbDialogRef<CustomerFormComponent>,
    private toastrService: NbToastrService,
    private uploadService: UploadService,
    private locationsService: LocationsService,
    private role: Role,
    public fb: FormBuilder,
    public usersService: UsersService,
    protected dialogService: NbDialogService,
    protected brandsService: BrandsService,
  ) {
  }

  ngOnInit(): void {
    this.optionItems = Object.values(this.customer.access_to_products);
    if (this.customer.access_to_products['virtual_try_on']['value']) {
      this.access = [...this.access, 'virtual_try_on'];
    }
    this.customer.add_new_brands = !!this.customer.add_new_brands;
    this.isAdmin = this.role.isAdmin();
    if (this.customer) {
      this.form.patchValue(Object.assign(this.customer, {access: this.access}));
      this.currencySelected = this.customer.currency;
    }
    if (!this.customer?.id && !this.form.get('license_id').value) {
      this.updateApiKey();
    }

    this.changeValidators();

    this.locationsService.getCountries().subscribe(res => {
      this.countryOptions = Object.keys(res).map(key => ({key, value: res[key]}));
      this.countryEvents();
    });

    this.setModifiersToLowercase();
    this.getBrandList();
  }

  setAdded(e) {
    this.form.get('add_new_brands').setValue(e);
  }

  getBrandList() {
    this.brandsService.getBrands({perPage: -1})
      .subscribe((response) => {
        this.brandsList = Object.keys(response.data).map(key => ({
          ...response.data[key],
          key,
          value: response.data[key].name
        }));


        this.allBrandsList = this.brandsList.map(el => el.id);
        if (!this.customer.id) {
          setTimeout(() => {
            this.form.get('bind').setValue(['all', ...this.allBrandsList]);
          }, 500);
        }
      });
  }

  changeSelectedBind() {
    let selected = this.form.get('bind').value;
    if (selected.includes('all')) {
      selected = [];
    } else {
      selected = ['all', ...this.allBrandsList];
    }
    this.form.get('bind').patchValue(selected);
  }

  gelBrandListForLabel(e) {
    if (e.filter(el => el !== 'all').length === this.allBrandsList.length && !e.includes('all')) {
      e = ['all', ...e];
    }
    if (e.filter(el => el !== 'all').length < this.allBrandsList.length && e.includes('all')) {
      const index = e.indexOf('all');
      if (index !== -1) {
        e.splice(index, 1);
      }
    }
    this.form.get('bind').patchValue(e);
  }

  gelBrandListForLabelText() {
    const selected = this.form.get('bind').value;
    if (!selected.length) {
      return;
    }
    if (selected.includes('all') && selected.length + 1 === this.allBrandsList.length) {
      return 'All Brands';
    }

    const firstElementIds = selected.slice(0, 5);
    const ele = this.brandsList.reduce((acc, el) => {
      if (firstElementIds.includes(el?.id)) {
        return [
          ...acc,
          el?.name
        ]
      }
      return [...acc]
    }, []);
    const more = this.form.get('bind').value.length - 5;
    return ele.join(', ') + (more >= 1 ? ` + ${more} pcs` : '');
  }

  setModifiersToLowercase() {
    this.subscriptions.push(this.fc.url.valueChanges
      .pipe(
        distinctUntilChanged(),
        map(val => val.toLowerCase())
      ).subscribe(newVal => {
        this.form.get('url').setValue(newVal);
      }));

    this.subscriptions.push(this.fc.website.valueChanges
      .pipe(
        distinctUntilChanged(),
        map(val => val.toLowerCase())
      ).subscribe(newVal => {
        this.form.get('website').setValue(newVal);
      }));
  }

  changeValidators() {
    const fields = {
      website: [Validators.required, Validators.pattern(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(\/)?$/)],
      type: [Validators.required],
      max_glass_count: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^(?![0\-\.])[0-9]*$/),
      ],
      url: [Validators.required, Validators.pattern(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(\/)?$/)],
      license_id: [Validators.required],
      file_license_id: [Validators.required],
    };

    for (const key of Object.keys(fields)) {
      const control = this.form.get(key);
      if (this.form.get('role').value === this.userRole.CUSTOMER) {
        control.setValidators(fields[key]);
      } else {
        control.clearValidators();
      }
      control.updateValueAndValidity();
    }
  }

  countryEvents() {
    this.filteredCountryOptions$ = this.fc.country.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.countryFilter(filterString)),
      );
  }

  stateEvents() {
    this.filteredStateOptions$ = this.fc.state.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.stateFilter(filterString)),
      );
  }

  cityEvents() {
    this.filteredCityOptions$ = this.fc.city.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.cityFilter(filterString)),
      );
  }

  private countryFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const options = this.countryOptions.filter(optionValue => optionValue.value.toLowerCase().includes(filterValue))
      .map(el => el.value);
    if (options?.length !== 1) {
      this.currentCountryCode = null;
    }
    return options;
  }

  private stateFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const options = this.stateOptions.filter(optionValue => optionValue.value.toLowerCase().includes(filterValue))
      .map(el => el.value);
    if (options?.length !== 1) {
      this.currentStateCode = null;
    }
    return options;
  }

  private cityFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cityOptions.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  cancel() {
    this.dialogRef.close(false);
  }

  submit() {
    const params = Object.assign({}, this.form.value);
    let request;
    params.bind = params.bind.filter(el => el !== 'all');
    if (!params.max_glass_count && this.prevValue === -1) {
      params.max_glass_count = this.prevValue;
    }
    if (params?.id) {
      const id = params?.id;
      delete (params.id);
      Object.keys(params).forEach(key => {
        if (!this.form.get(key)?.dirty) {
          delete (params[key]);
        }
      });
      if (!Object.keys(params).length) {
        this.toastrService.warning(null, 'Nothing to change');
        return;
      }
      request = this.usersService.updateUser(id, params);
    } else {
      if (!this.isMyProfile) {
        params.domains = [{
          license_id: params.license_id,
          url: params.url,
          type: params.type,
          max_glass_count: params.max_glass_count
        }];
      }
      delete (params.id);
      delete (params.license_id);
      delete (params.url);
      delete (params.type);
      if (!Object.keys(params).length) {
        this.toastrService.warning(null, 'Nothing to change');
        return;
      }
      request = this.usersService.createUser(params);
    }
    this.loading = true;
    request.subscribe(() => {
      this.loading = false;
      this.dialogRef.close(true);
    }, (errorResponse: HttpErrorResponse) => {
      this.loading = false;
      this.toastrService.danger(null, errorResponse.error?.message ? errorResponse.error.message : 'Error');
      this.form.setErrors(errorResponse.error.errors);
    });
  }

  addLicenseFile() {
    this.dialogService.open(UploadFileComponent, {
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {
        type: AttachmentType.DOMAIN_LICENSE,
        title: 'Add glasses license',
        accept: '.vlc'
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.form.get('file_license_id').setValue(data.id);
        this.form.get('file_license_id').markAsDirty();
        this.fileLicenseName = data.name;
      }
    });
  }

  addLogoFile() {
    this.dialogService.open(UploadFileComponent, {
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {
        type: AttachmentType.DOMAIN_LOGO,
        title: 'Add logo image',
        accept: 'image/png, image/jpeg'
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.form.get('logo_file_id').setValue(data.id);
        this.form.get('logo_file_id').markAsDirty();
        this.logoFileName = data.name;
      }
    });
  }

  deleteFile(type) {
    switch (type) {
      case 'license':
        const fileId = this.fc.file_license_id?.value;
        if (fileId) {
          this.uploadService.deleteFile([fileId]).subscribe(() => {
            this.form.get('file_license_id').setValue('');
            this.fileLicenseName = '';
            this.toastrService.success(null, 'File deleted!');
          });
        }
        break;
      case 'logo':
      default:
        const fileLogoId = this.fc.logo_file_id?.value;
        this.logoFileName = '';
        if (fileLogoId) {
          this.uploadService.deleteFile([fileLogoId]).subscribe(() => {

            this.form.get('logo_file_id').setValue('');
            this.toastrService.success(null, 'File deleted!');
          });
        }
    }
  }

  countryChanged(event) {
    if (event?.length) {
      this.currentCountryCode = this.countryOptions.find(el => el.value === event)?.key;
      if (this.currentCountryCode) {
        this.locationsService.getStates(this.currentCountryCode).subscribe(res => {
          this.stateOptions = Object.keys(res.data.states).map(key => ({key, value: res.data.states[key]}));
          this.stateEvents();
        });
      }
    }
  }

  stateChanged(event) {
    if (event?.length) {
      this.currentStateCode = this.stateOptions.find(el => el.value === event)?.key;
      if (this.currentCountryCode && this.currentStateCode) {
        this.locationsService.getCities(this.currentCountryCode, this.currentStateCode).subscribe(res => {
          if (res?.length) {
            this.cityOptions = res.map(el => `${el[0].toUpperCase()}${el.slice(1)}`.replace('_', ' '));
            this.cityEvents();
          }
        });
      }
    }
  }

  clearField(field) {
    this.form.get(field).setValue('');
    switch (field) { // d't use break to clear fields in a cascade
      case 'country':
        this.currentCountryCode = null;
        this.form.get('state').setValue('');
        this.stateOptions = [];
        this.stateEvents();
      case 'state':
        this.currentStateCode = null;
        this.form.get('city').setValue('');
        this.cityOptions = [];
        this.cityEvents();
    }
  }

  generateApiKey() {
    const passAt = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const passArray = Array.from({length: 29});

    return passArray.map((_, index) => {
      return index % 5 == 4 ? '-' : passAt.charAt(Math.random() * passAt.length)
    }).join('')
  }

  updateApiKey() {
    this.form.get('license_id').setValue(this.generateApiKey());
    this.form.get('license_id').markAsDirty();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  infinityChanged(e) {
    if (e.target.checked) {
      this.oldValue = this.form.controls.max_glass_count.value;
      this.form.controls.max_glass_count.setValidators([
        Validators.required
      ]);
      document.getElementById('max_glass_count').setAttribute('type', 'text');
      this.form.controls.max_glass_count.setValue('infinity');
      this.form.controls.max_glass_count.disable();
      this.prevValue = -1;
    } else {
      if (this.oldValue > 0) {
        this.form.controls.max_glass_count.setValue(this.oldValue)
      } else {
        this.form.controls.max_glass_count.setValue(null)
      }
      document.getElementById('max_glass_count').setAttribute('type', 'number');
      this.form.controls.max_glass_count.enable();
      this.form.controls.max_glass_count.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^(?![0\-\.])[0-9]*$/),
      ]);
      this.prevValue = 0;
    }
  }
}
