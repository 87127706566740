import {Router} from '@angular/router';
import {NbMenuItem, NbSearchService} from '@nebular/theme';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DomainsService } from '../../../shared/services/domains.service';
import {UsersService} from '../../../shared/services/users.service';
import { CustomerDomain } from 'src/app/shared/interfaces/customer-domain.interface';

import {PortalData} from '../../../shared/interfaces/portal-data.interface';

declare const portalData: PortalData;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggle = new EventEmitter<any>();
  @Input() profileMenu: NbMenuItem[];

  name = null;
  picture = null;
  value;
  selectDomain = null;
  domains: CustomerDomain[] = [];
  portalData: PortalData = portalData;

  constructor(
    private usersService: UsersService,
    private searchService: NbSearchService,
    private router: Router,
    private domainsService: DomainsService
  ) {
    this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        this.value = data.term;
        this.router.navigate(['/customer/search-results', this.value])
      });
  }

  ngOnInit() {
    this.domainsService.selectedDomainId.subscribe(id => {
      this.selectDomain = id;
    });

    this.usersService.getCurrentUserObservable().subscribe(user => {
        this.name = user?.name;
        this.picture = user?.photo?.path;
        this.domains = user?.domains;
    });
  }

  toggleSidebar() {
    this.toggle.emit();
  }

  setDomain(e) {
    this.domainsService.selectDomainId(e);
  }
}
